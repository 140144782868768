import * as React from 'react';
import Divider from 'wix-style-react/dist/src/Divider';
import type { IWsrDividerProps } from '../WsrDivider.props';
import { st, classes } from './WsrDivider.st.css';

const WsrDivider: React.FC<IWsrDividerProps> = props => {
  const { id, direction, skin } = props;

  return (
    <div id={id} className={st(classes.root, { direction })}>
      <Divider skin={skin} direction={direction} />
    </div>
  );
};

export default WsrDivider;
