import React from 'react';
import { createPortal } from 'react-dom';
import { MAX_Z_INDEX } from '@wix/thunderbolt-commons/dist/css';

const PARSED_MAX_Z_INDEX = parseInt(MAX_Z_INDEX, 10);

export function BlockingLayer({
  onClick: externalOnClick,
  targetId,
}: {
  targetId: string;
  onClick?: React.MouseEventHandler;
}) {
  const [rendered, setRendered] = React.useState(false);
  const componentRootRef = React.useRef<HTMLElement | null>(null);

  React.useEffect(() => {
    // render the blocking layer only after the
    // viewer component has been rendered
    setRendered(true);
    componentRootRef.current = document.getElementById(targetId);
  }, [targetId]);

  const onClick: React.MouseEventHandler = ev => {
    ev.stopPropagation();
    externalOnClick?.(ev);
  };

  return rendered && componentRootRef.current
    ? createPortal(
        <div
          onClick={onClick}
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: PARSED_MAX_Z_INDEX,
            ...(externalOnClick && { cursor: 'pointer' }),
          }}
        />,
        componentRootRef.current,
      )
    : null;
}
