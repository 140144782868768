import React from 'react';
import { usePrevious } from '../usePrevious';

export type IPreviewStateMapValue =
  | Array<IPreviewStateMapItem>
  | IPreviewStateMapItem;

export type IPreviewStateMap = Record<string, IPreviewStateMapValue>;

export interface IPreviewStateRootItem {
  state: string;
}

export interface IPreviewStateChildItem {
  selectors: Array<string> | string;
  type: 'single' | 'plural';
  state?: string;
}

export type IPreviewStateMapItem =
  | IPreviewStateRootItem
  | IPreviewStateChildItem;

export const isRootPreviewStateItem = (
  item: IPreviewStateRootItem | IPreviewStateChildItem,
): item is IPreviewStateRootItem => {
  if ('selectors' in item && 'type' in item) {
    return false;
  }
  return true;
};

const updateElementPreviewState = (
  previewState: string | null | undefined,
  element: HTMLElement,
  state?: string,
) => {
  if (!element) {
    return;
  }
  const { preview } = element.dataset;

  // Has new state
  if (previewState) {
    element.dataset.preview = state || previewState;
  }
  // No new state & has previous state — clear this state
  else if (preview) {
    delete element.dataset.preview;
  }
};

export const updatePreviewState = (
  previewState: string | null | undefined,
  element: HTMLElement,
  stateMapItem?: IPreviewStateMapValue,
) => {
  if (!stateMapItem) {
    return updateElementPreviewState(previewState, element);
  }

  const items = Array.isArray(stateMapItem) ? stateMapItem : [stateMapItem];

  items.forEach(item => {
    if (isRootPreviewStateItem(item)) {
      updateElementPreviewState(item.state, element);
    } else {
      const { selectors, type, state } = item;

      const assignPreviewStateToSelector = (selector: string) => {
        const selectedElements =
          type === 'single'
            ? [element.querySelector(selector)]
            : Array.from(element.querySelectorAll(selector));

        selectedElements.forEach(
          selectedElement =>
            selectedElement &&
            updateElementPreviewState(
              previewState,
              selectedElement as HTMLElement,
              state,
            ),
        );
      };

      if (Array.isArray(selectors)) {
        selectors.forEach(assignPreviewStateToSelector);
      } else {
        assignPreviewStateToSelector(selectors);
      }
    }
  });
};

export const clearPreviewStates = (
  element: HTMLElement,
  stateMapItem?: IPreviewStateMapValue,
) => {
  updatePreviewState(null, element, stateMapItem);
};

export function usePreviewState(
  id: string,
  compPreviewState: string | null | undefined,
  stateMap?: IPreviewStateMap,
) {
  const ref = React.useRef<HTMLElement>();
  const previewState = usePrevious(compPreviewState);

  React.useLayoutEffect(() => {
    if (!ref.current) {
      // Looking for the component (by compId) in current document or topmost document in case of iframe
      const element =
        document.getElementById(id) || top.document.getElementById(id);

      ref.current = element!;
    }

    if (previewState !== compPreviewState) {
      clearPreviewStates(ref.current, stateMap?.[previewState || '']);
    }

    updatePreviewState(
      compPreviewState,
      ref.current,
      stateMap?.[compPreviewState || ''],
    );
  }, [compPreviewState, stateMap, previewState, id]);
}
