/* eslint-disable no-use-before-define */
import type {
  SchemaConfig,
  IStylablePanelConfig,
  DefaultCompPlatformProps,
  IGFPPAction,
} from '@wix/editor-elements-types';
import { ObjectSchemaDefinition } from 'yup';
import { PropsSchemaBuilderFunction } from '../index';

import {
  root as yupRoot,
  PropsBuilderObjectSchemaDefinition,
  SchemaBuilder,
  enrich,
  PropsBuilderObjectSchema,
  SdkSchema,
  PropSchema,
} from '../yupWrapper';
import { OverridePanelsCallback } from './definePanels/types';
import type {
  SdkDict,
  SdkMixin,
  DefaultSdk,
  SdkFieldsConfig,
  SdkActionUpdateError,
} from './defineSdk';

type PropsSchemaExtension<T extends object> = {
  [method in keyof Omit<
    ExtendedPropsSchema<T>,
    keyof PropsBuilderObjectSchema<T>
  >]: (
    this: PropsBuilderObjectSchema<T>,
    ...args: Parameters<ExtendedPropsSchema<T>[method]>
  ) => PropsBuilderObjectSchema<T>; //
};

const enrichWithExtentions = <T extends object>(
  extensions: PropsSchemaExtension<T>,
  root: SchemaBuilder,
) => {
  Object.entries(extensions).forEach(([methodName, method]) => {
    enrich<PropsBuilderObjectSchema<T>>(root.object, methodName, method);
  });
  return root.object;
};

export type SupportedPanels<T> = {
  settingsPanel?: T;
  managePanel?: T;
  layoutPanel?: T;
  behaviorsPanel?: T;
  animationPanel?: T;
};

export type ComponentPanels = SupportedPanels<SchemaConfig>;
export interface ComponentActions {
  mainActions: Array<IGFPPAction>;
  enabledActions: Array<IGFPPAction>;
}
export interface ExtendedPropsSchema<T extends object> {
  definePanels(overridePanelsCallback: OverridePanelsCallback<T>): this;

  defineStylable(overrides?: Partial<IStylablePanelConfig>): this;

  // TODO replace this overloading mess with spread generic in TS 4.0
  // type Spread<A> = A extends [infer L, ...infer R] ?
  //   L & Spread<R> : unknown
  // function merge<A extends object[]>(a: [...A]) {
  //   return Object.assign({}, ...a) as Spread<A>;
  // }
  // const d = merge([{a: 1}, {b: 'c'}, {c: 'c'}])

  defineSdk<A extends SdkMixin>(
    sdkBuilder: ({ sdkMixin }: { sdkMixin: typeof SdkMixin }) => {
      mixins?: [A];
      fieldsConfig?: SdkFieldsConfig<T>;
    },
  ): ExtendedPropsSchema<T & SdkDict[A]>;

  defineSdk<A extends SdkMixin, B extends SdkMixin>(
    sdkBuilder: ({ sdkMixin }: { sdkMixin: typeof SdkMixin }) => {
      mixins?: [A, B];
      fieldsConfig?: SdkFieldsConfig<T>;
    },
  ): ExtendedPropsSchema<T & SdkDict[A] & SdkDict[B]>;

  defineSdk<A extends SdkMixin, B extends SdkMixin, C extends SdkMixin>(
    sdkBuilder: ({ sdkMixin }: { sdkMixin: typeof SdkMixin }) => {
      mixins?: [A, B, C];
      fieldsConfig?: SdkFieldsConfig<T>;
    },
  ): ExtendedPropsSchema<T & SdkDict[A] & SdkDict[B] & SdkDict[C]>;

  defineSdk<
    A extends SdkMixin,
    B extends SdkMixin,
    C extends SdkMixin,
    D extends SdkMixin,
  >(
    sdkBuilder: ({ sdkMixin }: { sdkMixin: typeof SdkMixin }) => {
      mixins?: [A, B, C, D];
      fieldsConfig?: SdkFieldsConfig<T>;
    },
  ): ExtendedPropsSchema<T & SdkDict[A] & SdkDict[B] & SdkDict[C] & SdkDict[D]>;

  // --------- END TODO

  // ----- The following functions should be marked private -----
  getPanels(): ComponentPanels;
  getGfpp(): ComponentActions;
  getStylablePanel(): Partial<IStylablePanelConfig>;
  getSdk(): SdkSchema;
  hasAddPanel(): boolean;
  transformPropsSchemaToDocumentSchema(): Record<string, any>;
  getAdditionalSchemas(): Record<string, any> | null;
}

const validatePropsSchema = <T extends object>(
  propsSchema: PropsBuilderObjectSchemaDefinition<T>,
) => {
  const isPropSchema = (_s: unknown): _s is PropSchema<any> => true;
  // Object.entries(propsSchema).forEach(([propName, schema]) => {
  Object.entries(propsSchema).forEach(([_, schema]) => {
    if (!isPropSchema(schema)) {
      return;
    }
    // TODO: Uncomment once defaults issue is resolved
    // const isDefaultValueDefined = schema.default() !== undefined;
    // if (!isDefaultValueDefined) {
    //   throw new Error(
    //     `prop '${propName}' was not properly defined, every prop *must* have a default value.`,
    //   );
    // }
  });
};

export const createSchema = <T extends object, K extends object | undefined>(
  defineSchema?: PropsSchemaBuilderFunction<T>,
  { isDraftSchema }: { isDraftSchema?: boolean } = {},
  defaultSchema?: K extends object ? PropsSchemaBuilderFunction<K> : undefined,
) => {
  type SchemaType = K extends object ? K & T : T;
  const schema = (
    defaultSchema
      ? {
          ...defaultSchema({ schemaBuilder: yupRoot }),
          ...(defineSchema && defineSchema({ schemaBuilder: yupRoot })),
        }
      : {
          ...(defineSchema && defineSchema({ schemaBuilder: yupRoot })),
        }
  ) as ObjectSchemaDefinition<SchemaType>;
  validatePropsSchema<SchemaType>(schema);
  return yupRoot.object<SchemaType>(schema).meta({ isDraftSchema });
};

export const createPropsSchema = <T extends object>(
  defineSchema: (args: {
    schemaBuilder: SchemaBuilder;
  }) => PropsBuilderObjectSchemaDefinition<T>,
  extensions: PropsSchemaExtension<T>,
) => {
  const props = defineSchema({ schemaBuilder: yupRoot });
  validatePropsSchema(props);
  const rootConstructor = enrichWithExtentions(extensions, yupRoot);
  return rootConstructor(props) as unknown as ExtendedPropsSchema<T>;
};

export type EditorType = 'classic' | 'responsive';

type UpdateComponentPropsInViewer<T> = {
  updateComponentPropsInViewer: (
    props: Partial<T & SdkActionUpdateError>,
  ) => void;
};

export type TransformRefs<T extends object> = {
  [K in keyof T]: T[K] extends { $ref: string } ? JSX.Element : T[K];
};

export type InferPropsSchema<T> = T extends ExtendedPropsSchema<infer P>
  ? TransformRefs<P>
  : never;

export type InferExtendedPropsSchema<T> = T extends ExtendedPropsSchema<infer P>
  ? TransformRefs<P> &
      DefaultSdk & {
        editorType?: EditorType;
      } & UpdateComponentPropsInViewer<P> &
      DefaultCompPlatformProps
  : never;
