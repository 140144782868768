import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types';

import { props as viewerPropsMapper } from './WbuTextInput.mapper';

const props = composeMapper<PreviewWrapperProps<any>, any, any>()(
  viewerPropsMapper,
  { add: ['componentViewMode'] },
  (resolver, dependencies, carmiData) => {
    const viewerProps = resolver(dependencies, carmiData);
    const { componentViewMode } = dependencies;

    return {
      ...viewerProps,
      usePatternValidation:
        componentViewMode === 'editor'
          ? false
          : viewerProps.usePatternValidation,
      readOnly: componentViewMode === 'editor' ? false : viewerProps.readOnly,
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
