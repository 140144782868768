import * as React from 'react';
import {
  Composites,
  InfoIcon,
  SectionDivider,
  TextLabel,
} from '@wix/wix-base-ui/lib/esm';
import type { IWbuSectionDividerProps } from '../WbuSectionDivider.props';
import WbuContainer from '../../WbuContainer/WbuContainer';
import './WbuSectionDivider.global.scss';

const WbuSectionDivider: React.FC<IWbuSectionDividerProps> = props => {
  const { id, sectionTitle, tooltipInfo, editorType } = props;

  return (
    <WbuContainer id={id} editorType={editorType}>
      <Composites.SectionDividerWithInfoIcon>
        {tooltipInfo && <InfoIcon text={tooltipInfo} />}
        <SectionDivider>
          <TextLabel value={sectionTitle || 'Section Divider'} />
        </SectionDivider>
      </Composites.SectionDividerWithInfoIcon>
    </WbuContainer>
  );
};

export default WbuSectionDivider;
