import * as React from 'react';
import InfoIcon, { InfoIconSize } from 'wix-style-react/dist/src/InfoIcon';
import TextButton from 'wix-style-react/dist/src/TextButton';
import Text from 'wix-style-react/dist/src/Text';
import type { IWsrInfoIconProps } from '../WsrInfoIcon.props';
import { classes } from './WsrInfoIcon.st.css';

const WsrInfoIcon: React.FC<IWsrInfoIconProps> = props => {
  const { id, tooltipText, size, actionText, actionLink, onActionClick } =
    props;

  const renderContent = React.useMemo(() => {
    const handleActionClick: React.MouseEventHandler<HTMLElement> = () => {
      if (actionLink) {
        window.open(actionLink, '_blank');
      }
      onActionClick?.({ type: 'actionClick' });
    };

    return (
      <div className={classes.tooltipContent}>
        {tooltipText && (
          <Text light size="small">
            {tooltipText}
          </Text>
        )}
        {actionText && (
          <TextButton
            skin="light"
            size="small"
            underline="always"
            onClick={handleActionClick}
          >
            {actionText}
          </TextButton>
        )}
      </div>
    );
  }, [tooltipText, actionText, actionLink, onActionClick]);

  return (
    <div id={id}>
      <InfoIcon
        size={size.toLowerCase() as InfoIconSize}
        content={renderContent}
      />
    </div>
  );
};

export default WsrInfoIcon;
