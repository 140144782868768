import type { IComponentController } from '@wix/editor-elements-types';
import type { IWbuSliderProps } from '../../../components/WbuSlider/WbuSlider.props';

const mapActionsToProps = ({ updateProps }) => ({
  updateComponentPropsInViewer: (props: Partial<IWbuSliderProps>) => {
    updateProps(props);
  },
});

const mapStateToProps = ({ editorType }) => ({
  editorType,
});

const compController: IComponentController = {
  mapStateToProps,
  mapActionsToProps,
};

export default compController;
