import * as React from 'react';
import MessageModalLayout, {
  MessageModalLayoutProps,
} from 'wix-style-react/dist/src/MessageModalLayout';
import Text from 'wix-style-react/dist/src/Text';
import Checkbox from 'wix-style-react/dist/src/Checkbox';
import { ModalTheme } from 'wix-style-react/dist/src/BaseModalLayout';
import type { IWsrMessageModalLayoutProps } from '../WsrMessageModalLayout.props';
import { THEME_OPTIONS } from '../WsrMessageModalLayout.props';
import { usePlatformImage } from '../../../providers/usePlatformImage';
import { usePlatformIcon } from '../../../providers/usePlatformIcon';

const WsrMessageModalLayout: React.FC<IWsrMessageModalLayoutProps> = props => {
  const {
    id,
    content,
    title,
    illustration,
    primaryButtonText,
    secondaryButtonText,
    primaryButtonPrefix,
    primaryButtonSuffix,
    primaryButtonLink,
    secondaryButtonPrefix,
    secondaryButtonSuffix,
    secondaryButtonLink,
    sideActionsCheckbox,
    sideActionsCheckboxTitle,
    footnote,
    footnoteContent,
    footnoteLinkText,
    footnoteLinkUrl,
    helpButton,
    theme,
    onCloseButtonClick,
    onCheckboxToggle,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
    onHelpButtonClick,
  } = props;

  const [isCheckboxChecked, setIsCheckboxChecked] =
    React.useState<boolean>(false);

  const modalTheme = theme
    ? Object.keys(THEME_OPTIONS).find(
        key => THEME_OPTIONS[key as keyof typeof THEME_OPTIONS] === theme,
      )
    : undefined;

  const primaryButtonPrefixIcon = usePlatformIcon({
    svgId: primaryButtonPrefix?.svgId,
  });
  const primaryButtonSuffixIcon = usePlatformIcon({
    svgId: primaryButtonSuffix?.svgId,
  });
  const secondaryButtonPrefixIcon = usePlatformIcon({
    svgId: secondaryButtonPrefix?.svgId,
  });
  const secondaryButtonSuffixIcon = usePlatformIcon({
    svgId: secondaryButtonSuffix?.svgId,
  });

  const primaryButtonProps =
    primaryButtonPrefix.svgId || primaryButtonSuffix.svgId
      ? {
          suffixIcon: primaryButtonSuffixIcon,
          prefixIcon: primaryButtonPrefixIcon,
        }
      : undefined;

  const primaryButtonOnClick =
    primaryButtonLink || onPrimaryButtonClick
      ? () => {
          if (primaryButtonLink) {
            window.open(primaryButtonLink, '_blank');
          }

          onPrimaryButtonClick?.({ type: 'primaryButtonClick' });
        }
      : undefined;

  const secondaryButtonProps =
    secondaryButtonPrefix.svgId || secondaryButtonSuffix.svgId
      ? {
          suffixIcon: secondaryButtonSuffixIcon,
          prefixIcon: secondaryButtonPrefixIcon,
        }
      : undefined;

  const secondaryButtonOnClick =
    secondaryButtonLink || onSecondaryButtonClick
      ? () => {
          if (secondaryButtonLink) {
            window.open(secondaryButtonLink, '_blank');
          }

          onSecondaryButtonClick?.({ type: 'secondaryButtonClick' });
        }
      : undefined;

  const modalIllustration = usePlatformImage({
    svgId: illustration?.svgId,
    height: '120px',
    width: '120px',
  });

  const handleCloseButtonClick: MessageModalLayoutProps['onCloseButtonClick'] =
    () => {
      return onCloseButtonClick?.({ type: 'closeButtonClick' });
    };

  const handleCheckboxToggle = (isChecked: boolean) => {
    return onCheckboxToggle?.({ type: 'checkboxToggle', isChecked });
  };

  const sideActions = sideActionsCheckbox && (
    <Checkbox
      checked={isCheckboxChecked}
      onChange={() => {
        setIsCheckboxChecked(!isCheckboxChecked);
        handleCheckboxToggle(!isCheckboxChecked);
      }}
    >
      {sideActionsCheckboxTitle}
    </Checkbox>
  );

  const modalFootnote = footnote && (
    <Text size="small">
      {footnoteContent}{' '}
      {footnoteLinkText && (
        <a
          href={footnoteLinkUrl ? footnoteLinkUrl : ''}
          target="_blank"
          rel="noreferrer"
        >
          {footnoteLinkText}
        </a>
      )}
    </Text>
  );

  const handleHelpButtonClick = helpButton
    ? () => {
        onHelpButtonClick?.({ type: 'helpButtonClick' });
      }
    : undefined;

  return (
    <div id={id}>
      <MessageModalLayout
        theme={modalTheme as ModalTheme}
        title={title}
        primaryButtonText={primaryButtonText}
        primaryButtonProps={primaryButtonProps}
        primaryButtonOnClick={primaryButtonOnClick}
        secondaryButtonText={secondaryButtonText}
        secondaryButtonProps={secondaryButtonProps}
        secondaryButtonOnClick={secondaryButtonOnClick}
        illustration={modalIllustration}
        sideActions={sideActions}
        footnote={modalFootnote}
        onHelpButtonClick={handleHelpButtonClick}
        onCloseButtonClick={handleCloseButtonClick}
      >
        <Text>{content}</Text>
      </MessageModalLayout>
    </div>
  );
};

export default WsrMessageModalLayout;
