interface IControlTypeFieldsMap {
  inputSingleLine: Array<'label' | 'tooltipInfo' | 'placeholder'>;
  inputMultiLine: Array<'label' | 'tooltipInfo' | 'placeholder'>;
  numericInput: Array<'label' | 'tooltipInfo' | 'placeholder'>;
  dropdown: Array<'label' | 'tooltipInfo' | 'placeholder'>;
  mediaSelection: Array<'label' | 'tooltipInfo'>;
  slotSelection: Array<unknown>;
  radioButtons: Array<'label' | 'tooltipInfo'>;
  slider: Array<'label' | 'tooltipInfo'>;
  toggleSwitch: Array<'label'>;
  manageOptions: Array<
    | 'label'
    | 'newChoice'
    | 'renameLabel'
    | 'addNewChoice'
    | 'copyOfChoice'
    | 'uncheckDefault'
    | 'checkByDefault'
    | 'itemValuePrefix'
    | 'checkUnavailable'
    | 'unCheckUnavailable'
    | 'replaceIllustration'
    | 'uniqueValueTooltipError'
    | 'emptyStringTooltipError'
  >;
  colorPicker: Array<'label'>;
  fontPicker: Array<'label' | 'tooltipInfo' | 'placeholder'>;
}

export type ControlType = keyof IControlTypeFieldsMap;

const controlTypeFieldsMap: IControlTypeFieldsMap = {
  inputSingleLine: ['label', 'tooltipInfo', 'placeholder'],
  inputMultiLine: ['label', 'tooltipInfo', 'placeholder'],
  numericInput: ['label', 'tooltipInfo', 'placeholder'],
  dropdown: ['label', 'tooltipInfo', 'placeholder'],
  mediaSelection: ['label', 'tooltipInfo'],
  slotSelection: [],
  radioButtons: ['label', 'tooltipInfo'],
  slider: ['label', 'tooltipInfo'],
  toggleSwitch: ['label'],
  manageOptions: [
    'label',
    'uncheckDefault',
    'checkByDefault',
    'itemValuePrefix',
    'renameLabel',
    'replaceIllustration',
    'addNewChoice',
    'newChoice',
    'copyOfChoice',
    'checkUnavailable',
    'unCheckUnavailable',
    'uniqueValueTooltipError',
    'emptyStringTooltipError',
  ],
  colorPicker: ['label'],
  fontPicker: ['label', 'tooltipInfo', 'placeholder'],
};

export const getTranslatedFields = <CType extends ControlType>(
  controlType: CType,
) => controlTypeFieldsMap?.[controlType] ?? [];
