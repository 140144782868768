import type { IComponentController } from '@wix/editor-elements-types';
import type { IWbuToggleSwitchProps } from '../../../components/WbuToggleSwitch/WbuToggleSwitch.props';

const mapActionsToProps = ({ updateProps }) => ({
  updateComponentPropsInViewer: (props: Partial<IWbuToggleSwitchProps>) => {
    updateProps(props);
  },
});

const mapStateToProps = ({ editorType }) => ({
  editorType,
});

const compController: IComponentController = {
  mapStateToProps,
  mapActionsToProps,
};

export default compController;
