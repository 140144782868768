
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "WbuNotificationBanner3685664769",
      {"classes":{"root":"WbuNotificationBanner3685664769__root"},"vars":{},"stVars":{},"keyframes":{}},
      "",
      -1,
      module.id
  );
  