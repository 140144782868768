import * as React from 'react';
import {
  Composites,
  TextLabel,
  FontFamilyPicker,
  ColorPickerInput,
  InfoIcon,
} from '@wix/wix-base-ui/lib/esm';

import { getCurrentSelectablefontsWithParams } from '@wix/santa-core-utils/dist/fonts';
import WbuContainer from '../../WbuContainer/WbuContainer';
import { IWbuFontAndColorPickerProps } from '../WbuFontAndColorPicker.props';

import './WbuFontAndColorPicker.global.scss';

const fonts = getCurrentSelectablefontsWithParams('UGC', ['latin']);

const WbuFontAndColorPicker: React.FC<IWbuFontAndColorPickerProps> = props => {
  const {
    id,
    label,
    color,
    font,
    divider,
    tooltip,
    updateComponentPropsInViewer,
    onChange,
  } = props;

  const _onChange = (val: string) => {
    updateComponentPropsInViewer({ font: val });
    onChange?.({ type: 'change' } as React.ChangeEvent);
  };

  return (
    <WbuContainer id={id} divider={divider}>
      <Composites.DropDownWithColorInputLabeled>
        <InfoIcon text={tooltip} />
        <TextLabel type="T02" value={label} />
        <Composites.DropDownWithColorInput>
          <FontFamilyPicker
            fonts={fonts}
            value={font}
            getMissingFontName={() => {
              return 'Arial';
            }}
            onChange={_onChange}
            shouldTranslate={false}
          />
          <ColorPickerInput value={color} />
        </Composites.DropDownWithColorInput>
      </Composites.DropDownWithColorInputLabeled>
    </WbuContainer>
  );
};

export default WbuFontAndColorPicker;
