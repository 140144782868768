import {
  CompInfo,
  AnyCompDef,
  NativeExtension,
  CompPreviewInfo,
  CompInfoDependency,
  CompPreviewInfoDependency,
} from '@wix/editor-elements-types';
import { ExtendedMapperFunc } from './composeMapper.types';

export const composeMapper =
  <
    TProps extends Record<string, any>,
    TDefinition extends AnyCompDef,
    TCarmiData = undefined,
  >() =>
  <
    TOriginalProps extends Record<string, any>,
    TDependencies extends CompPreviewInfoDependency<TDefinition>,
    TNativeDependencies extends CompInfoDependency<TDefinition>,
    TExcludedDependencies extends TNativeDependencies,
  >(
    originalMapper: NativeExtension<
      TOriginalProps,
      TDefinition,
      TNativeDependencies,
      TCarmiData
    >,
    {
      add,
      remove,
    }: {
      add?: Array<TDependencies>;
      remove?: Array<TExcludedDependencies>;
    },
    mapperFunc: ExtendedMapperFunc<
      TProps,
      TOriginalProps,
      TDefinition,
      TDependencies,
      TNativeDependencies,
      TCarmiData
    >,
  ) => {
    const originalDeps = remove
      ? Object.keys(originalMapper.deps).filter(
          dependency => !remove.includes(dependency as TExcludedDependencies),
        )
      : Object.keys(originalMapper.deps);
    const mergedDeps = add ? originalDeps.concat(add) : originalDeps;
    const deps = mergedDeps.reduce(
      (acc, key) => ({ ...acc, [key]: true }),
      {} as Record<
        keyof Omit<
          Pick<
            CompPreviewInfo<TDefinition>,
            TDependencies | TNativeDependencies
          >,
          TExcludedDependencies
        >,
        true
      >,
    );

    const resolver = (
      data: Pick<CompInfo<TDefinition>, TNativeDependencies> &
        Pick<CompPreviewInfo<TDefinition>, TDependencies>,
      carmiData: TCarmiData,
    ) => mapperFunc(originalMapper.resolver, data, carmiData);

    return {
      deps,
      resolver,
    };
  };
