import type { IComponentController } from '@wix/editor-elements-types';
import type { IWbuFontAndColorPickerProps } from '../../../components/WbuFontAndColorPicker/WbuFontAndColorPicker.props';

const mapActionsToProps = ({ updateProps }) => ({
  updateComponentPropsInViewer: (
    props: Partial<IWbuFontAndColorPickerProps>,
  ) => {
    updateProps(props);
  },
});

const mapStateToProps = ({ editorType }) => ({
  editorType,
});

const compController: IComponentController = {
  mapStateToProps,
  mapActionsToProps,
};

export default compController;
