import * as React from 'react';
import { Composites, RichText } from '@wix/wix-base-ui/lib/esm';
import type { IWbuRichTextProps } from '../WbuRichText.props';
import WbuContainer from '../../WbuContainer/WbuContainer';
import './WbuRichText.global.scss';

const WbuRichText: React.FC<IWbuRichTextProps> = props => {
  const {
    id,
    richTextContent,
    richTextLink,
    richTextLabel,
    alignTextToCenter,
    divider,
    editorType,
  } = props;

  const link = (
    <a
      style={{ whiteSpace: 'nowrap' }}
      href={richTextLink}
      target={'_blank'}
      rel="noreferrer"
    >
      {richTextLabel}
    </a>
  );

  return (
    <WbuContainer id={id} divider={divider} editorType={editorType}>
      <Composites.RichText
        className={alignTextToCenter ? 'composite-centered' : ''}
      >
        <RichText>
          {richTextContent}&nbsp;{link}
        </RichText>
      </Composites.RichText>
    </WbuContainer>
  );
};

export default WbuRichText;
