import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

const props = withCompInfo<any, any>()(['compData'], ({ compData }) => {
  return {
    ...{
      labelPosition: 'top',
      statusMessage: '',
      status: 'none',
      autoFocus: false,
      required: false,
      readOnly: false,
      pattern: '',
      size: 'medium',
      maxLength: 0,
      suffix: {},
      prefix: {},
      defaultValue: '',
      placeholder: 'Write Something...',
      infoContent: '',
      value: '',
      label: 'Input Title',
    },
    ...compData,
  };
});

const css = withCompInfo()([], () => {
  return {
    height: 'auto',
  };
});

export default createComponentMapperModel({
  props,
  css,
});
