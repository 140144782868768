import * as React from 'react';
import {
  Composites,
  TextLabel,
  InfoIcon,
  Checkbox,
} from '@wix/wix-base-ui/lib/esm';
import type { IWbuCheckboxGroupProps } from '../WbuCheckboxGroup.props';
import WbuContainer from '../../WbuContainer/WbuContainer';
import './WbuCheckboxGroup.global.scss';

const WbuCheckboxGroup: React.FC<IWbuCheckboxGroupProps> = props => {
  const {
    id,
    tooltipInfo,
    fieldTitle,
    options,
    divider,
    onChange,
    updateComponentPropsInViewer,
    editorType,
  } = props;

  const _onChange = (optionId: string, checked: boolean) => {
    const newOptions = options.map(option => {
      if (option.value === optionId) {
        return {
          ...option,
          isDefault: checked,
        };
      }
      return {
        ...option,
      };
    });
    updateComponentPropsInViewer({ options: newOptions });

    onChange?.({ type: 'change' } as React.ChangeEvent);
  };

  return (
    <WbuContainer id={id} divider={divider} editorType={editorType}>
      <Composites.CheckboxesLabeled>
        {tooltipInfo && <InfoIcon text={tooltipInfo} />}
        <TextLabel type="T02" value={fieldTitle} />
        {options.map((option, key) => (
          <Checkbox
            label={option.label}
            key={key.toString()}
            type="T07"
            labelAfterSymbol
            onChange={(checked: boolean) => _onChange(option.value, checked)}
            value={option.isDefault ? true : false}
          />
        ))}
      </Composites.CheckboxesLabeled>
    </WbuContainer>
  );
};

export default WbuCheckboxGroup;
