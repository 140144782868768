import { PropsBuilderObjectSchema } from '..';
import { definePanelsMediator } from './definePanels/definePanels';
import { Metadata } from './metadata';

const isPanelEmpty = ({ schema }: any) =>
  Object.keys(schema?.properties).length === 0;

export const getPanels = <T extends object>(
  props: PropsBuilderObjectSchema<T>,
) => {
  let metadata: Metadata = props.describe().meta || {};
  if (
    !metadata.settingsPanel ||
    !metadata.manageOptionsPanel ||
    !metadata.layoutPanel
  ) {
    metadata = definePanelsMediator(props).describe().meta;
  }

  const { settingsPanel, manageOptionsPanel, layoutPanel } = metadata;
  return {
    ...(!isPanelEmpty(layoutPanel) ? { layoutPanel } : {}),
    ...(!isPanelEmpty(settingsPanel) ? { settingsPanel } : {}),
    ...(!isPanelEmpty(manageOptionsPanel)
      ? { managePanel: manageOptionsPanel! }
      : {}),
  };
};
