import { LeanHandlersRepo, LeanPanelHandler } from '@wix/editor-elements-types';
import { Schema } from 'yup';
import { PropsBuilderObjectSchema } from '../..';
import { Control } from './control';
import { ControlAPI, ControlsAPI, IPanelAPI, SupportedPanel } from './types';

type Fields<Props extends object> = { [k in keyof Props]: Schema<Props[k]> };
type RegisterHandler<Props extends object, ReturnType = void> = (
  handler: LeanPanelHandler<Props, ReturnType>,
) => [number, () => void];

export class PanelAPI<Props extends object> implements IPanelAPI<Props> {
  controls: ControlsAPI<Props>;
  private _handlersRepo: LeanHandlersRepo<Props> = {};

  constructor(
    public readonly title: string,
    props: PropsBuilderObjectSchema<Props>,
    defaultPropsFilter?: SupportedPanel['defaultPropsFilter'],
  ) {
    const fields = defaultPropsFilter ? defaultPropsFilter(props).fields : {};

    this.controls = {
      push: (...controls: Array<keyof Props>) => {
        controls.forEach(control => {
          (this.controls[control] as ControlAPI<Props, any>) = new Control(
            props.fields[control] as any,
            this.registerHandler,
          );
        });
      },
      omit: (...controls: Array<keyof Props>) => {
        controls.forEach(control => {
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          delete this.controls[control];
        });
      },
      ...(Object.entries(fields) as Array<[keyof Props, any]>).reduce(
        (
          acc: Record<keyof Props, ControlAPI<Props, any>>,
          [propName, field],
        ) => {
          acc[propName] = new Control(field, this.registerHandler);
          return acc;
        },
        {} as Record<keyof Props, ControlAPI<Props, any>>,
      ),
    };
  }

  registerHandler: RegisterHandler<Props> = handler => {
    const id = Object.keys(this._handlersRepo).length;
    this._handlersRepo[id] = handler;

    return [
      id,
      () => {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete this._handlersRepo[id];
      },
    ];
  };

  get fields() {
    return Object.entries(this.controls).reduce<Fields<Props>>(
      (acc, [key, control]) => {
        if (control instanceof Control) {
          (acc as any)[key] = control.field;
        }
        return acc;
      },
      {} as Fields<Props>,
    );
  }

  get handlersRepo() {
    return this._handlersRepo;
  }
}
