import { useEffect, useRef, useState } from 'react';

export type UseResetComponentOptions = {
  id: string;
  shouldResetComponent?: boolean;
  onResetComponent?: () => void;
};

// Returns a key that updates every time the `shouldResetComponent` render flag becomes true
export function useResetComponent({
  id,
  shouldResetComponent,
  onResetComponent,
}: UseResetComponentOptions) {
  // Whether component was mounted
  const didMountRef = useRef(false);
  // Goes up any time component resets
  const [iteration, setIteration] = useState(0);

  useEffect(() => {
    const didMount = didMountRef.current;
    if (!didMount) {
      didMountRef.current = true;
      return;
    }
    if (shouldResetComponent) {
      setIteration(currentIteration => currentIteration + 1);
      onResetComponent?.();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldResetComponent]);

  // Component key
  return `${id}-${iteration}`;
}
