import type { IGFPPAction } from '@wix/editor-elements-types';
import { getLeanBuildContextData } from '../utils';

export const getPanelTitle = (panelType: IGFPPAction['actionType']) => {
  const { compName, namespace } = getLeanBuildContextData();

  return [namespace, compName, 'panelTitle', panelType]
    .filter(Boolean)
    .join('_');
};
