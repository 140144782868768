import * as React from 'react';
import { Composites, ToggleSwitch } from '@wix/wix-base-ui/lib/esm';
import type { IWbuToggleSwitchProps } from '../WbuToggleSwitch.props';
import WbuContainer from '../../WbuContainer/WbuContainer';
import './WbuToggleSwitch.global.scss';

const WbuToggleSwitch: React.FC<IWbuToggleSwitchProps> = props => {
  const {
    id,
    label,
    toggleSwitch,
    disabled,
    divider,
    updateComponentPropsInViewer,
    onChange,
    editorType,
  } = props;

  const _onChange = (val: boolean) => {
    updateComponentPropsInViewer({ toggleSwitch: val });
    onChange?.({ type: 'change' } as React.ChangeEvent);
  };

  return (
    <WbuContainer id={id} divider={divider} editorType={editorType}>
      <Composites.ToggleSwitch>
        <ToggleSwitch
          label={label || 'Label'}
          value={toggleSwitch}
          disabled={disabled}
          onChange={_onChange}
        />
      </Composites.ToggleSwitch>
    </WbuContainer>
  );
};

export default WbuToggleSwitch;
