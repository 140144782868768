import { CodecsObject, LeanTypes } from './types';
import {
  extractMax,
  extractMin,
  isArrayInnerType,
  isValidLeanType,
  hasValidAdditionalSchemas,
} from './utils';
import { fromYup } from './leanSchemaTransformer';
import { DocumentSchemaTypes } from './ds-schemas';
import {
  SmallStringMaxLength,
  TextMaxLength,
  BigStringMaxLength,
} from './schemasConstraints';
import type { LeanSchema } from './lean-schemas';

const compact = (obj: Record<string, any>) =>
  Object.entries(obj).reduce(
    (acc, [k, val]) => ({
      ...acc,
      ...(val !== undefined ? { [k]: val } : {}),
    }),
    {},
  );

const textCodec: CodecsObject['text'] = {
  transformToDs(input) {
    return {
      ...compact({
        default: input.default,
      }),
      maxLength: TextMaxLength,
      type: 'string',
    };
  },
};

const bigStringCodec: CodecsObject['big_string'] = {
  transformToDs(input) {
    return {
      ...compact({
        default: input.default,
      }),
      maxLength: BigStringMaxLength,
      type: 'string',
    };
  },
};

const smallStringCodec: CodecsObject['small_string'] = {
  transformToDs(input) {
    return {
      ...compact({
        default: input.default,
      }),
      maxLength: SmallStringMaxLength,
      type: 'string',
    };
  },
};

const doubleCodec: CodecsObject['double'] = {
  extractAdditionalData(yupSchema) {
    return {
      min: extractMin(yupSchema),
      max: extractMax(yupSchema),
    };
  },
  transformToDs(input) {
    return {
      ...compact({
        default: input.default,
        minimum: input.min,
        maximum: input.max,
      }),
      type: 'number',
    };
  },
};

const integerCodec: CodecsObject['integer'] = {
  extractAdditionalData(yupSchema) {
    return {
      min: extractMin(yupSchema),
      max: extractMax(yupSchema),
    };
  },
  transformToDs(input) {
    return {
      ...compact({
        default: input.default,
        minimum: input.min,
        maximum: input.max,
      }),
      type: 'number',
      multipleOf: 1.0,
    };
  },
};

const booleanCodec: CodecsObject['boolean'] = {
  transformToDs(input) {
    return {
      ...compact({
        default: input.default,
      }),
      type: 'boolean',
    };
  },
};

const shapeCodec: CodecsObject['shape'] = {
  extractAdditionalData(input) {
    return {
      fieldsLeanSchema: fromYup(input.fields as any),
    };
  },
  transformToDs(input) {
    const properties = Object.entries(input.fieldsLeanSchema).reduce(
      (acc, [propName, leanSchema]) => {
        const codec = codecs[leanSchema.type];
        acc[propName] = codec.transformToDs(leanSchema);
        return acc;
      },
      {} as DocumentSchemaTypes.ObjectSchema['properties'],
    );
    const required = Object.entries(input.fieldsLeanSchema).reduce(
      (acc, [propName, leanSchema]) => {
        if (leanSchema.required) {
          acc.push(propName);
        }
        return acc;
      },
      [] as Array<string>,
    );
    return {
      properties,
      required,
      type: 'object',
    };
  },
};

const arrayCodec: CodecsObject['array'] = {
  extractAdditionalData(input) {
    const metadata = input.describe();
    if (!isArrayInnerType(metadata)) {
      throw new Error(
        `expected array to contain a valid inner type (make sure you used schemaBuilder for array inner type)`,
      );
    }
    const { type } = metadata.innerType.meta.leanSchema;
    if (!isValidLeanType(type)) {
      throw new Error(
        `got inner type '${type}' but expected one of [${Object.values(
          LeanTypes,
        ).join(', ')}]`,
      );
    }
    const { _p: innerSchema } = fromYup({
      _p: (input as any).innerType,
    });
    const additionalSchemas = hasValidAdditionalSchemas(metadata)
      ? metadata.meta.additionalSchemas
      : undefined;
    return {
      default: input.default(),
      innerSchema,
      additionalSchemas,
    };
  },
  transformToDs(input: LeanSchema.ItemsArray) {
    const refTypes = input.additionalSchemas
      ? Object.keys(input.additionalSchemas)
      : [];
    return {
      default: input.default || [],
      type: 'array',
      pseudoType: ['refList'],
      shouldCollect: true,
      shouldValidate: true,
      referencedMap: '#',
      refTypes,
    };
  },
};

const stringEnumCodec: CodecsObject['string_enum'] = {
  extractAdditionalData(input) {
    const { oneOf } = input.describe() as any;
    return {
      enum: oneOf,
    };
  },
  transformToDs(input) {
    return {
      ...compact({
        default: input.default,
      }),
      enum: input.enum,
      type: 'string',
    };
  },
};

const fontPickerCodec: CodecsObject['font_picker'] = {
  transformToDs(input) {
    return {
      ...compact({
        default: input.default,
      }),
      maxLength: 1200,
      format: 'font-family',
      type: 'string',
    };
  },
};

const slotCodec: CodecsObject['slot'] = {
  transformToDs() {
    return {
      $ref: 'SlotRef',
    };
  },
};

const svgCodec: CodecsObject['svg'] = {
  transformToDs() {
    return {
      $ref: 'SVGReference',
    };
  },
};

const colorPickerCodec: CodecsObject['color_picker'] = {
  transformToDs(input) {
    return {
      ...compact({
        default: input.default,
      }),
      maxLength: 120000,
      type: 'string',
    };
  },
};

const codecs: CodecsObject = {
  big_string: bigStringCodec,
  text: textCodec,
  small_string: smallStringCodec,
  integer: integerCodec,
  double: doubleCodec,
  shape: shapeCodec,
  boolean: booleanCodec,
  array: arrayCodec,
  string_enum: stringEnumCodec,
  slot: slotCodec,
  svg: svgCodec,
  color_picker: colorPickerCodec,
  font_picker: fontPickerCodec,
};

export default codecs;
