import { PropsBuilderObjectSchema } from '../..';
import { getPanelTitle } from '../../translations/getPanelTitle';
import { panelToJsonSchemaConvertor } from './jsonSchemaConverter';
import { PanelAPI } from './panelsAPI';
import { supportedPanels } from './supportedPanels';
import { IPanelAPI, OverridePanelsCallback, PanelsBuilderAPI } from './types';

function buildPanelsAPI<Props extends object>(
  props: PropsBuilderObjectSchema<Props>,
) {
  return supportedPanels.reduce<PanelsBuilderAPI<Props>>((acc, panel) => {
    acc[panel.key] = new PanelAPI<Props>(
      getPanelTitle(panel.gfppActionType),
      props.clone(),
      panel.defaultPropsFilter,
    );
    return acc;
  }, {});
}

function convertToJsonSchema<Props extends object>(
  panelsAPI: PanelsBuilderAPI<Props>,
) {
  return (
    Object.entries(panelsAPI) as Array<
      [keyof PanelsBuilderAPI, IPanelAPI<Props>]
    >
  ).reduce<Partial<Record<keyof PanelsBuilderAPI, any>>>(
    (acc, [panelKey, panelAPI]) => {
      acc[panelKey] = panelToJsonSchemaConvertor(panelAPI);
      return acc;
    },
    {},
  );
}

export function definePanelsMediator<T extends object>(
  props: PropsBuilderObjectSchema<T>,
  overridePanelsCallback?: OverridePanelsCallback<T>,
) {
  const panelsAPI = buildPanelsAPI(props);
  if (typeof overridePanelsCallback === 'function') {
    overridePanelsCallback(panelsAPI);
  }
  return props.meta(convertToJsonSchema(panelsAPI));
}
