import { StringSchema } from 'yup';
import {
  DropDown,
  RadioButtons,
  FieldDefinition,
  TextSingleLine,
  TextMultiLine,
  ToggleSwitch,
  NumericInput,
  ManageOptions,
  Slider,
  MediaSelection,
  SlotSelection,
  ColorPicker,
  FontPicker,
} from '@wix/editor-elements-types';
import { getTranslatedFields } from '../translations/getTranslatedFields';
import { ControlTypeKey } from './types';
import { ControlSchemaBuilder, createControlSchemaBuilder } from './builder';
import { ControlFactoryConfig, createControlSchemaFactory } from './utils';

const generateTranslateSchemaFields = <T extends string>(
  values: Array<T>,
  schemaBuilder: ControlSchemaBuilder,
): Record<T, StringSchema<string>> =>
  values.reduce(
    (acc, field) => ({
      ...acc,
      [field]: schemaBuilder.string().translate(),
    }),
    {} as Record<T, StringSchema<string>>,
  );

export function controlPropsDefaults(
  config: Omit<ControlFactoryConfig, 'schemaBuilder'>,
): FieldDefinition {
  const params = {
    ...config,
    schemaBuilder: createControlSchemaBuilder(),
  };
  const { metaData } = config;

  if (!metaData || !metaData.leanPanel) {
    throw new Error(
      `prop '${config.originProp}' declared without any metaData. It is not longer supported, and thus a panel control cannot be generated for it!`,
    );
  }

  const controlType: ControlTypeKey = metaData.leanPanel.controlType;
  switch (controlType) {
    case 'small_string':
      return SingeLineTextInputPropsFactory(params).cast();
    case 'big_string':
      return MultiLineTextInputPropsFactory(params).cast();
    case 'text':
      return MultiLineTextInputPropsFactory(params).cast();
    case 'double':
      return NumericInputPropsFactory(params).cast();
    case 'integer':
      return NumericInputPropsFactory(params).cast();
    case 'boolean':
      return ToggleSwitchPropsFactory(params).cast();
    case 'options':
      return ManageOptionsPropsFactory(params).cast();
    case 'dropdown':
      return DropDownPropsFactory(params).cast();
    case 'radioButtons':
      return RadioButtonsPropsFactory(params).cast();
    case 'slider':
      return SliderPropsFactory(params).cast();
    case 'slot_selection':
      return SlotSelectionPropsFactory(params).cast();
    case 'media_selection':
      return MediaSelectionPropsFactory(params).cast();
    case 'color_picker':
      return ColorPickerPropsFactory(params).cast();
    case 'font_picker':
      return FontPickerPropsFactory(params).cast();

    default:
      throw new Error(
        `prop '${config.originProp}' declared as type '${controlType}' which is not supported, and thus a panel control cannot be generated for it!`,
      );
  }
}

/**
 * Lean Panels Developer - Add/Change Panels' Control Props Schema here:
 */

export const SingeLineTextInputPropsFactory =
  createControlSchemaFactory<TextSingleLine>(
    ({ schemaBuilder }) => ({
      ...generateTranslateSchemaFields(
        getTranslatedFields('inputSingleLine'),
        schemaBuilder,
      ),
    }),
    'inputSingleLine',
  );

export const MultiLineTextInputPropsFactory =
  createControlSchemaFactory<TextMultiLine>(
    ({ schemaBuilder }) => ({
      ...generateTranslateSchemaFields(
        getTranslatedFields('inputMultiLine'),
        schemaBuilder,
      ),
    }),
    'inputMultiLine',
  );

export const NumericInputPropsFactory =
  createControlSchemaFactory<NumericInput>(
    ({ schemaBuilder }) => ({
      ...generateTranslateSchemaFields(
        getTranslatedFields('numericInput'),
        schemaBuilder,
      ),
    }),
    'numericInput',
  );

export const SliderPropsFactory = createControlSchemaFactory<Slider>(
  ({ schemaBuilder, metaData, tests }) => {
    const [minTest, maxTest] =
      tests?.map(({ params }: { [k: string]: any }) => params) || [];
    return {
      ...generateTranslateSchemaFields(
        getTranslatedFields('slider'),
        schemaBuilder,
      ),
      min: schemaBuilder.integer().default(minTest?.min),
      max: schemaBuilder.integer().default(maxTest?.max),
      unit: schemaBuilder.string().default(metaData?.unit),
      step: schemaBuilder.double().default(metaData?.step),
    };
  },
  'slider',
);

export const ToggleSwitchPropsFactory =
  createControlSchemaFactory<ToggleSwitch>(
    ({ schemaBuilder }) => ({
      ...generateTranslateSchemaFields(
        getTranslatedFields('toggleSwitch'),
        schemaBuilder,
      ),
    }),
    'toggleSwitch',
  );

export const DropDownPropsFactory = createControlSchemaFactory<DropDown>(
  ({ enum: dropdownEnum, schemaBuilder }) => ({
    ...generateTranslateSchemaFields(
      getTranslatedFields('dropdown'),
      schemaBuilder,
    ),
    enum: schemaBuilder.mixed().default(dropdownEnum),
  }),
  'dropdown',
);

export const FontPickerPropsFactory = createControlSchemaFactory<FontPicker>(
  ({ schemaBuilder }) => ({
    ...generateTranslateSchemaFields(
      getTranslatedFields('fontPicker'),
      schemaBuilder,
    ),
  }),
  'fontPicker',
);

export const ManageOptionsPropsFactory =
  createControlSchemaFactory<ManageOptions>(({ schemaBuilder, metaData }) => {
    const multiSelect = metaData ? !!metaData?.multiSelect : false;
    const showUnavailable = metaData ? !!metaData?.showUnavailable : false;
    const withIcon = metaData ? (metaData?.withIcon ? true : false) : false;
    const customItemType = metaData
      ? metaData?.customItemType
        ? metaData?.customItemType
        : undefined
      : undefined;
    const withContainer = metaData ? !!metaData?.withContainer : false;

    return {
      ...generateTranslateSchemaFields(
        getTranslatedFields('manageOptions'),
        schemaBuilder,
      ),
      options: schemaBuilder.itemArray(),
      multiSelect: schemaBuilder.boolean().default(multiSelect),
      showUnavailable: schemaBuilder.boolean().default(showUnavailable),
      withIcon: schemaBuilder.boolean().default(withIcon),
      customItemType: schemaBuilder.string().default(customItemType),
      withContainer: schemaBuilder.boolean().default(withContainer),
    };
  }, 'manageOptions');

export const RadioButtonsPropsFactory =
  createControlSchemaFactory<RadioButtons>(
    ({ enum: radioButtonsEnum, schemaBuilder }) => ({
      ...generateTranslateSchemaFields(
        getTranslatedFields('radioButtons'),
        schemaBuilder,
      ),
      options: schemaBuilder.mixed().default(radioButtonsEnum),
    }),
    'radioButtons',
  );

export const SlotSelectionPropsFactory =
  createControlSchemaFactory<SlotSelection>(() => ({}), 'slotSelection');

export const ColorPickerPropsFactory = createControlSchemaFactory<ColorPicker>(
  ({ schemaBuilder }) => ({
    ...generateTranslateSchemaFields(
      getTranslatedFields('colorPicker'),
      schemaBuilder,
    ),
  }),
  'colorPicker',
);

export const MediaSelectionPropsFactory =
  createControlSchemaFactory<MediaSelection>(
    ({ schemaBuilder, metaData }) => ({
      ...generateTranslateSchemaFields(
        getTranslatedFields('mediaSelection'),
        schemaBuilder,
      ),
      preset: schemaBuilder.mixed().default(metaData?.preset),
    }),
    'mediaSelection',
  );
