import type { FullPanelDefinition } from '@wix/editor-elements-types';
import { controlPropsDefaults } from '../../panelControls';
import { extractPropsValidationArgs } from '../propsValidations';
import { IPanelAPI } from './types';

const extractExtraData = (yupSchema: any) => {
  const whitelist = yupSchema._whitelist?.toArray() || [];
  return {
    enum: whitelist.length > 0 ? whitelist : null,
  };
};

const createPanelFieldsDefinition = <T extends object>(
  panelAPI: IPanelAPI<T>,
) => {
  return (Object.entries(panelAPI.fields) as Array<[keyof T, any]>).reduce(
    (result: FullPanelDefinition<T>, [propName, yupSchema]) => {
      const extraData = extractExtraData(yupSchema);
      const { type } = yupSchema;
      const propValidationRule = extractPropsValidationArgs(yupSchema);
      const { tests } = propValidationRule;
      result[propName] = {
        ...controlPropsDefaults({
          originProp: propName as string,
          metaData: yupSchema.meta(),
          tests,
          ...extraData,
        }),
        type,
        propValidationRule,
        visibilityHandlerRefs:
          panelAPI.controls[propName].visibilityHandlerRefs,
        disabledHandlerRefs: panelAPI.controls[propName].disabledHandlerRefs,
      };
      return result;
    },
    {} as FullPanelDefinition<T>,
  );
};

export function panelToJsonSchemaConvertor<T extends object>(
  panelAPI: IPanelAPI<T>,
) {
  const fields = createPanelFieldsDefinition(panelAPI);

  return {
    schema: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      title: panelAPI.title,
      properties: { ...fields },
    },
    uiDefinition: {
      sections: [
        {
          fields: Object.keys(fields),
        },
      ],
    },
    handlersRepo: panelAPI.handlersRepo,
  };
}
