import { DocumentSchemaTypes } from './ds-schemas';
import codecs from './codecs';
import { LeanSchema } from './lean-schemas';
import { LeanTypes } from './types';

export function transformToDocumentSchema(
  leanSchemaRoot: LeanSchema.Root,
  options: {
    isDraft: boolean;
  },
): DocumentSchemaTypes.RootSchema {
  return {
    ...basicTransformToDocumentSchema(leanSchemaRoot),
    isDraftSchema: options.isDraft,
  };
}

export function basicTransformToDocumentSchema(
  leanSchemaRoot: LeanSchema.Root,
): DocumentSchemaTypes.RootSchema {
  const schema = codecs.shape.transformToDs({
    type: LeanTypes.shape,
    fieldsLeanSchema: leanSchemaRoot,
    required: true,
  });
  return {
    ...schema,
    isTopLevelDataNode: true,
  };
}
