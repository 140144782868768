/* eslint-disable no-console */
import { LEAN_COMPONENTS_TRANSLATION_KEY_PREFIX } from '../constants';

export const getNamespace = () =>
  global.__leanBuildContext?.getNamespace() ??
  LEAN_COMPONENTS_TRANSLATION_KEY_PREFIX;

export const getCompName = () => global.__leanBuildContext?.getCompName();

export const getLeanBuildContextData = () => {
  const namespace = getNamespace();
  const compName = getCompName();

  return { namespace, compName };
};

export const getComponentNameFromPropsFile = (
  propsFilePath: string,
): string => {
  return propsFilePath.match(/([^/]+)\.props\.ts$/)![1];
};

export const lines = (lns: Array<string>) => lns.join('\n');

export const hr = new Array(100).join('*');

export const executeWithErrorHandling = <ReturnValue extends any = any>(
  exec: () => ReturnValue,
  executionFailureMessage: string,
) => {
  try {
    return exec();
  } catch (error) {
    console.error(lines([hr, executionFailureMessage, hr]));
    console.error(error);
    console.error(hr);
    throw error;
  }
};
