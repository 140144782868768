// TODO: cleanup whe DS ready - https://wix.slack.com/archives/GC7DBFW72/p1583165492013100?thread_ts=1582820948.009200&cid=GC7DBFW72
import type {
  GeneralInfoObject as DSGeneralInfoObject,
  CompStructure as DSCompStructure,
  LanguageDefinition as DSLanguageDefinition,
  DocumentServicesObject as DSDocumentServicesObject,
  ClientRect as DSClientRect,
  CurrentRouteInfo,
  RouterDynamicPages,
  CompRef,
  CompVariantPointer,
  Link,
  SeoObject,
  MobileObject,
} from '@wix/document-services-types';

export const enum InteractionType {
  Hover = 'Hover',
  Click = 'Click',
}

export type CompData = {
  [key: string]: any;
};

export interface RouterSiteMapItem {
  changeFrequency: unknown;
  lastModified: unknown;
  pageName: string;
  priority: unknown;
  title: string;
  url: string;
}

// TODO: add to DS
export type DocumentServiceErrorTypes = {
  save: {
    UNKNOWN_SERVER_ERROR: 'UNKNOWN_SERVER_ERROR';
    HTTP_REQUEST_ERROR: 'HTTP_REQUEST_ERROR';

    SAVE_DISABLED_IN_DOCUMENT_SERVICES: 'SAVE_DISABLED_IN_DOCUMENT_SERVICES';
    SAVE_BLOCKED_BY_DOCUMENT_SERVICES: 'SAVE_BLOCKED_BY_DOCUMENT_SERVICES';
    SAVE_IN_PROGRESS: 'SAVE_IN_PROGRESS';
    NOT_LOGGED_IN: 'NOT_LOGGED_IN';
    SESSION_EXPIRED: 'SESSION_EXPIRED';
    USER_NOT_AUTHORIZED_FOR_SITE: 'USER_NOT_AUTHORIZED_FOR_SITE';
    CONCURRENT_SAVE: 'CONCURRENT_SAVE_ERROR';
    SITE_NAME_TAKEN: 'SITE_NAME_TAKEN_ERROR';
    DUPLICATE_COMPONENTS: 'DUPLICATE_COMPONENTS';
    DATA_REFERENCE_MISMATCH: 'DATA_REFERENCE_MISMATCH';
    MISSING_CONTAINERS: 'MISSING_CONTAINERS';
    APP_CONTROLLER_REFERENCE_MISMATCH: 'APP_CONTROLLER_REFERENCE_MISMATCH';
    DESIGN_REFERENCE_MISMATCHES: 'DESIGN_REFERENCE_MISMATCHES';
    BEHAVIOR_REFERENCE_MISMATHCHES: 'BEHAVIOR_REFERENCE_MISMATHCHES';
    PROPERTY_REFERENCE_MISMATCHES: 'PROPERTY_REFERENCE_MISMATCHES';
    STYLE_REFERENCE_MISMATCHES: 'STYLE_REFERENCE_MISMATCHES';
    CONNECTION_LIST_REFERENCE_MISMATCHES: 'CONNECTION_LIST_REFERENCE_MISMATCHES';
    SITE_DESERIALIZATION_ERROR: 'SITE_DESERIALIZATION_ERROR';
    SAVE_PUBLISH_DISABLED_ON_SERVER: 'SAVE_PUBLISH_DISABLED_ON_SERVER';
    SAVE_PUBLISH_RC_FAILED_ON_SERVER: 'SAVE_PUBLISH_RC_FAILED_ON_SERVER';
    CONCURRENT_AUTO_SAVE: 'CONCURRENT_AUTO_SAVE';
    SITE_STALE_STATE_FROM_AUTO_SAVE: 'SITE_STALE_STATE_FROM_AUTO_SAVE';
    AUTOSAVE_DISABLE_FROM_SERVER: 'AUTOSAVE_DISABLE_FROM_SERVER';
  };
};

type ValueOf<T> = T extends ReadonlyArray<any> ? T[number] : T[keyof T];

// TODO: add to DS
export type DocumentServiceErrorType = ValueOf<
  DocumentServiceErrorTypes['save']
>;

// TODO: add to DS
export type DocumentServiceError = {
  // NOTE: deprecated?
  document: {
    errorCode: number;
    errorType: DocumentServiceErrorType;
  };
  documentServices: {
    errorCode: number;
    errorType: DocumentServiceErrorType;
  };
};

// TODO: add to DS
export interface PagesBackgroundObject {
  get(pageId: string, device: string): { isPreset: boolean };
}

// TODO: add to DS
export interface GeneralInfoObject extends DSGeneralInfoObject {
  getCurrentRevisionPublicUrl(): string;
}

export interface CompStructure extends DSCompStructure {
  componentTypeForBI?: string;
}

type BiEventFieldTypeMap = {
  string: string;
  number: number; // TODO: is it correct type name?
  numeric: number;
  bool: boolean;
  boolean: boolean; // TODO: is it correct type name?
  guid: string;
  datetime: Date;
  url: string;
};

type BiEventFieldType = keyof BiEventFieldTypeMap;

export type BiEventDefinition = {
  evid?: number;
  eventId?: number; // TODO: why evid vs eventId in some events?
  src?: number;
  params?: Record<string, string>; // TODO: why prams vs fields in some events?
  fields?: Record<string, BiEventFieldType>;
};

export type BiEventFields<T extends Record<string, BiEventFieldType> = any> = {
  [key in keyof T]?: BiEventFieldTypeMap[T[key]];
};

export interface BiErrorDefinition {
  errorCode: number;
  errorName: string;
  severity: 'error' | 'fatal' | 'warning' | 'recoverable';
  dsc?: string;
  params?: {
    p1?: string;
    p2?: string;
    p3?: string;
    p4?: string;
  };
}

export interface LanguageDefinition extends DSLanguageDefinition {
  machineTranslationLanguageCode?: string;
}

export type ThemeObject = { [themeName: string]: string };

export type BiErrorFields = Record<string, unknown>;

export type SendBIFunction = <T extends BiEventDefinition>(
  biEventDefinition: T,
  biEventFields?: any, // BiEventFields<T['fields']>,
) => void;

export type SendBIErrorFunction = (
  error: BiErrorDefinition,
  params: BiErrorFields,
) => void;

export interface FileSystemDescriptor {
  attributes: {
    readOnly: boolean;
  };
  directory: boolean;
  eTag: 'virtual';
  length: number;
  localTimeStamp: number;
  location: string;
  name: string;
  virtual: boolean;
}

export interface FileSystem {
  getRoots: () => Record<string, FileSystemDescriptor>;
  getChildren: (
    FileSystemDescriptor: any,
  ) => Promise<Array<FileSystemDescriptor>>;
  getViewerInfo: () => {
    gridAppId: string;
  };
  getVirtualDescriptor: (filePath: string) => FileSystemDescriptor;
  writeFile: (
    descriptor: FileSystemDescriptor,
    fileContent: string,
  ) => Promise<void>;
  readFile: (descriptor: FileSystemDescriptor) => Promise<string>;
  deleteFile: (descriptor: FileSystemDescriptor) => Promise<void>;
  flush: () => Promise<void>;
}

export interface PendingApp {
  appDefinitionId: string;
}

// TODO: add to DS
export type DocumentServicesObject = DSDocumentServicesObject & {
  data: {
    createItem(id: string): any;
  };
  generalInfo: DSDocumentServicesObject['generalInfo'] & {
    getCurrentRevisionPublicUrl(): string;
    media: DSGeneralInfoObject['media'] & {
      getMediaAuthToken: () => string;
    };
  };
  pages: DSDocumentServicesObject['pages'] & {
    // NOTE: invalid type
    remove: (
      pageId: string,
      callback?: () => void,
      options?: { shouldShowEditorRemovePanel: boolean },
    ) => void;
  };
  // TODO: move to DS https://jira.wixpress.com/browse/EK-876
  routers: DSDocumentServicesObject['routers'] & {
    getCurrentInnerRoute(): CurrentRouteInfo;
    getRouterSiteMap(
      routerId: string,
      callback: (result: Array<RouterSiteMapItem>) => void,
    ): void;
    get: DSDocumentServicesObject['routers']['get'] & {
      all(): Record<string, RouterDynamicPages>;
    };
  };
  components: DSDocumentServicesObject['components'] & {
    stylable: {
      getEditorInstance: () => unknown;
      forceState: (
        compRef: CompRef,
        options: {
          selector: string;
          selectionSelector: string;
        },
      ) => void;
      revertForceState: (compRef: CompRef) => void;
    };
    layout: DSDocumentServicesObject['components']['layout'] & {
      measure: DSDocumentServicesObject['components']['layout']['measure'] & {
        getInnerElementBoundingClientRects: (
          compRef: CompRef,
          selector: string,
        ) => Array<DSClientRect>;
      };
    };
    transformations: DSDocumentServicesObject['components']['transformations'] & {
      get(compVariantPointer: CompVariantPointer): any;
    };
  };
  theme: DSDocumentServicesObject['theme'] & {
    colors: DSDocumentServicesObject['theme']['colors'] & {
      // NOTE: `unknown` return type in DS
      getColorPresets(): Array<{
        paletteName: string;
        paletteTags: unknown;
        color_1: string;
        color_2: string;
      }>;
      getAll(): ThemeObject;
    };
    fonts: DSDocumentServicesObject['theme']['fonts'] & {
      getAll(): ThemeObject;
    };
  };
  tpa: DSDocumentServicesObject['tpa'] & {
    app: DSDocumentServicesObject['tpa']['app'] & {
      registerOnInstalled(appDefId: string, callback: () => void): void;
      getRenderedReactCompsByApplicationId(
        applicationId: number,
      ): Array<CompRef>;
      refreshApp(compRef: CompRef): void;
    };
    getPendingApps(): Array<PendingApp>;
    getPremiumPendingApps(): Array<PendingApp>;
    pending: {
      getPendingApps(): Array<{
        appDefId: string;
        applicationId: number;
        reason?: string;
      }>;
      add(appDefId: string): void;
      dismiss(appDefId: string): void;
    };
  };
  errors: DocumentServiceErrorTypes;
  appStudio: {
    build(
      onSuccess: () => void,
      onError: (error?: unknown) => void,
      versionType: string,
      appName: string,
    ): void;
    appName: {
      validate: (appName: string) => any;
      ERRORS: {
        INVALID_CHARS: 'INVALID_CHARS';
        EMPTY_NAME: 'EMPTY_NAME';
        INVALID_LENGTH: 'INVALID_LENGTH';
        ONLY_SPACES: 'ONLY_SPACES';
      };
      getUsedAppNames: (onSuccess: any, onError: any) => Array<string>;
    };
  };
  wixCode: {
    getClientSpec: () => {
      instance: string;
    };
    isProvisioned: () => boolean;
    provision: ({
      onSuccess,
      onError,
    }: {
      onSuccess: () => void;
      onError: () => void;
    }) => void;
    fileSystem: FileSystem;
  };
  wixapps: DSDocumentServicesObject['wixapps'] & {
    classics: DSDocumentServicesObject['wixapps']['classics'] & {
      blog: {
        deleteAllAppParts(): any;
      };
      getDataStoreId(str: string): string;
    };
  };
  save(
    onSuccess: () => void,
    onError: (error?: unknown) => void,
    isFullSave: boolean,
    options: unknown,
  ): void;
  saveAsTemplate(
    onSuccess: () => void,
    onError: (error?: unknown) => void,
  ): void;
  publish(
    onSuccess: (results: { siteRcExists?: boolean }) => void,
    onError: (error: unknown) => void,
    options?: { publishRC?: boolean },
  ): void;
  initAutosave(config: {
    enabled: boolean;
    // Why `| string`? see santa-editor/packages/savePublish/constants/constants.ts, where values are getting via `experiments.getValue(key: string): string`
    AUTOSAVE_ACTION_COUNT?: number | string;
    SAVE_AFTER_AUTOSAVE_COUNT?: number | string;
    AUTOSAVE_TIMEOUT?: number | string;
    DEBOUNCE_WAIT: number;
    onDiffSaveStarted?: (trigger: string) => void;
    onDiffSaveFinished?: (error: DocumentServiceError, trigger: string) => void;
    onPartialSaveStarted?: (trigger: string) => void;
    onPartialSaveFinished?: (
      error: DocumentServiceError,
      trigger: string,
    ) => void;
  }): void;
  getAutoSaveInfo():
    | undefined
    | {
        shouldAutoSave: boolean;
      };
  bi: {
    event: SendBIFunction;
    error: SendBIErrorFunction;
  };
  siteMembers: {
    isJoinCommunityEnabled: () => boolean;
    isAutoApproval: () => boolean;
    isLoginDialogFirst: () => boolean;
    isSignupPoliciesFieldEnabled: (policy: string) => boolean;
    isSocialLoginEnabled: (vendor: string) => boolean;
    setJoinCommunityStatus: (toggle: boolean) => void;
    setAutoApproval: (toggle: boolean) => void;
    setLoginDialogFirst: (toggle: boolean) => void;
    getSignupPoliciesLink: (policy: string) => Link;
    setSocialLoginVendorStatus: (
      vendor: 'google' | 'facebook',
      status: boolean,
    ) => void;
    setSignupPoliciesFieldStatus: (policy: string, val: boolean) => void;
    setSignupPoliciesLink: (policy: string, val: Link) => void;
    getCustomSignupPageId: () => string;
    getCustomSignInPageId: () => string;
    setCustomSignupPageId: (pageId: string) => void;
    setCustomSignInPageId: (pageId: string) => void;
  };
  seo: SeoObject & {
    title: { get(): string };
    description: { get(): string };
    keywords: { get(): string };
  };
  mobile: MobileObject & {
    // `mobile?.mobileOnlyComponents?.` because editor X, which uses classic editor panels API, does not have `dsRead.mobile.mobileOnlyComponents`
    // https://github.com/wix-private/editorx-santa-editor-bridge/blob/090fc4089df8655b642fd44bda4ae2b490c201d2/packages/santa-editor-bridge/src/main/classicEditorPackage.tsx#L718-L722
    // TODO: cleanup when editorX ready with real `dsRead.mobile` mock
    mobileOnlyComponents?: any;
  };
};

// TODO: find a way to split DocumentServicesObject into two types
export type DSRead = DocumentServicesObject;
export type DSAction = DocumentServicesObject;
