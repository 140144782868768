import _ from 'lodash';
import { Schema } from 'yup';
import codecs from './codecs';
import { LeanSchema } from './lean-schemas';
import { isValidLeanType, isYupMetadataLeanSchema } from './utils';

const removeExplicitUndefined = (obj: object) =>
  _.pickBy(obj, e => e !== undefined);

const validateSchema = (key: string, schema: Schema<any>) => {
  const metadata = schema.meta();
  if (!isYupMetadataLeanSchema(metadata)) {
    throw new Error(
      `invalid schema metadata object for prop ${key} (got: ${JSON.stringify(
        metadata,
      )})`,
    );
  }
  const { leanSchema } = metadata;
  if (!isValidLeanType(leanSchema.type)) {
    throw new Error(
      `unsupported schema type ${leanSchema.type} for property '${key}'`,
    );
  }
  return leanSchema;
};

export const fromYup = (
  propsObject: Record<string, Schema<any>>,
): LeanSchema.Root => {
  return Object.entries(propsObject).reduce((acc, [key, schema]) => {
    const { type } = validateSchema(key, schema);
    const codec = codecs[type];
    const required = schema.describe().tests.some(t => t.name === 'required');
    const _default = schema.default();
    const optionalData = removeExplicitUndefined({
      default: _default,
      ...codec.extractAdditionalData?.(schema as any),
    });
    acc[key] = {
      type,
      required: required || _default === undefined,
      ...optionalData,
    };
    return acc;
  }, {} as LeanSchema.Root);
};
