import * as React from 'react';
import TextButton, {
  TextButtonSize,
  TextButtonSkin,
  TextButtonWeight,
  TextButtonUnderline,
} from 'wix-style-react/dist/src/TextButton';
import { usePlatformIcon } from '../../../providers/usePlatformIcon';
import {
  IWsrTextButtonProps,
  SIZES,
  SKINS,
  UNDERLINES,
  WEIGHTS,
} from '../WsrTextButton.props';

const WsrTextButton: React.FC<IWsrTextButtonProps> = props => {
  const {
    id,
    value,
    linkTo,
    prefixIcon,
    suffixIcon,
    disabled,
    onClick = () => {},
    onMouseIn,
    onMouseOut,
    ...rest
  } = props;

  const addLink = linkTo ? { href: linkTo, target: '_blank' } : {};
  const platformPrefixIcon = usePlatformIcon(prefixIcon);
  const platformSuffixIcon = usePlatformIcon(suffixIcon);

  const handleMouseIn: React.MouseEventHandler<HTMLElement> = () =>
    onMouseIn?.({ type: 'mouseIn' });

  const handleMouseOut: React.MouseEventHandler<HTMLElement> = () =>
    onMouseOut?.({ type: 'mouseOut' });

  const size = Object.keys(SIZES).find(
    key => SIZES[key] === rest.size,
  ) as TextButtonSize;
  const skin = Object.keys(SKINS).find(
    key => SKINS[key] === rest.skin,
  ) as TextButtonSkin;
  const weight = Object.keys(WEIGHTS).find(
    key => WEIGHTS[key] === rest.weight,
  ) as TextButtonWeight;
  const underline = Object.keys(UNDERLINES).find(
    key => UNDERLINES[key] === rest.underline,
  ) as TextButtonUnderline;

  return (
    <div id={id}>
      <TextButton
        {...addLink}
        as={linkTo ? 'a' : undefined}
        skin={skin}
        size={size}
        onClick={onClick}
        prefixIcon={platformPrefixIcon}
        suffixIcon={platformSuffixIcon}
        disabled={disabled}
        underline={underline}
        weight={weight}
        onMouseEnter={handleMouseIn}
        onMouseLeave={handleMouseOut}
      >
        {value}
      </TextButton>
    </div>
  );
};

export default WsrTextButton;
