import type { IComponentController } from '@wix/editor-elements-types';
import type { IWbuCornerRadiusInputProps } from '../../../components/WbuCornerRadiusInput/WbuCornerRadiusInput.props';

const mapActionsToProps = ({ updateProps }) => ({
  updateComponentPropsInViewer: (
    props: Partial<IWbuCornerRadiusInputProps>,
  ) => {
    updateProps(props);
  },
});

const mapStateToProps = ({ editorType }) => ({
  editorType,
});

const compController: IComponentController = {
  mapStateToProps,
  mapActionsToProps,
};

export default compController;
