import * as React from 'react';
import classnames from 'classnames';
import {
  Composites,
  InfoIcon,
  Button,
  TextLabel,
} from '@wix/wix-base-ui/lib/esm';
import WbuContainer from '../../WbuContainer/WbuContainer';
import type { IWbuButtonProps } from '../WbuButton.props';
import './WbuButton.global.scss';

const WbuButton: React.FC<IWbuButtonProps> = props => {
  const {
    id,
    label,
    fieldTitle,
    tooltipInfo,
    size,
    skin,
    divider,
    onClick = () => {},
    editorType,
  } = props;
  const sizes: Record<IWbuButtonProps['size'], string> = {
    small: 'btn-sm',
    medium: 'btn-md',
    large: 'btn-big',
  };

  const skins: Record<IWbuButtonProps['skin'], string> = {
    standard: '', // standard is the default, no class is needed here
    'standard secondary': 'btn-confirm-secondary',
    premium: 'btn-premium-primary',
    'premium secondary': 'btn-premium-secondary',
    alert: 'btn-danger-primary',
    'alert secondary': 'btn-danger-secondary',
    inverted: 'btn-inverted',
  };

  return (
    <WbuContainer id={id} divider={divider} editorType={editorType}>
      <Composites.ButtonLargeLabeled>
        {tooltipInfo && <InfoIcon text={tooltipInfo} />}
        {fieldTitle && <TextLabel value={fieldTitle} />}
        <Button
          onClick={onClick}
          className={classnames(sizes[size], skins[skin])}
        >
          {label}
        </Button>
      </Composites.ButtonLargeLabeled>
    </WbuContainer>
  );
};

export default WbuButton;
