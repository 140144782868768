
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "WsrHeading824297118",
      {"classes":{"root":"WsrHeading824297118__root"},"vars":{"width":"--width"},"stVars":{},"keyframes":{}},
      "",
      -1,
      module.id
  );
  