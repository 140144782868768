export const defaultIcon = () => `<svg
            xmlns="http://www.w3.org/2000/svg"
            width="72"
            height="72"
            viewBox="0 0 72 72"
          >
            <g transform="translate(23 27)">
              <path
                fill="#4EB7F5"
                d="M30,21 C30,22.104 29.104,23 28,23 L2,23 C0.896,23 0,22.104 0,21 L0,2 C0,0.896 0.896,0 2,0 L28,0 C29.104,0 30,0.896 30,2 L30,21 Z"
                class="illus-clr-2"
              />
              <path
                fill="#D3EDFF"
                d="M14.8828,20 L10.7788,14.815 C10.4268,14.37 9.7758,14.308 9.3448,14.677 L3.1338,20 L14.8828,20 Z"
                class="illus-clr-3"
              />
              <path
                fill="#D3EDFF"
                d="M19.2593,11.673 C19.6993,11.307 20.3543,11.382 20.6993,11.838 L20.6993,11.838 L26.8633,20 L9.2893,20 Z M6.5664,4.5 C7.6704,4.5 8.5664,5.396 8.5664,6.5 C8.5664,7.604 7.6704,8.5 6.5664,8.5 C5.4624,8.5 4.5664,7.604 4.5664,6.5 C4.5664,5.396 5.4624,4.5 6.5664,4.5 Z"
                class="illus-clr-3"
              />
            </g>
            <g transform="translate(18 22)">
              <path
                fill="#4EB7F5"
                d="M30,21 C30,22.104 29.104,23 28,23 L2,23 C0.896,23 0,22.104 0,21 L0,2 C0,0.896 0.896,0 2,0 L28,0 C29.104,0 30,0.896 30,2 L30,21 Z"
                class="illus-clr-1"
              />
              <path
                fill="#D3EDFF"
                d="M14.8828,20 L10.7788,14.815 C10.4268,14.37 9.7758,14.308 9.3448,14.677 L3.1338,20 L14.8828,20 Z"
                class="illus-clr-3"
              />
              <path
                fill="#D3EDFF"
                d="M19.2593,11.673 C19.6993,11.307 20.3543,11.382 20.6993,11.838 L20.6993,11.838 L26.8633,20 L9.2893,20 Z M6.5664,4.5 C7.6704,4.5 8.5664,5.396 8.5664,6.5 C8.5664,7.604 7.6704,8.5 6.5664,8.5 C5.4624,8.5 4.5664,7.604 4.5664,6.5 C4.5664,5.396 5.4624,4.5 6.5664,4.5 Z"
                class="illus-clr-3"
              />
            </g>
          </svg>`;
