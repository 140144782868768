import * as React from 'react';
import PreviewWidget from 'wix-style-react/dist/src/PreviewWidget';
import { createSlotsContainer } from '@wix/editor-elements-integrations/dist/thunderbolt/createSlotsContainer';
import type { IWsrPreviewWidgetProps } from '../WsrPreviewWidget.props';
import { classes } from './WsrPreviewWidget.st.css';

const WsrPreviewWidget: React.FC<IWsrPreviewWidgetProps> = props => {
  const {
    id,
    backgroundFill,
    previewWidgetContainer,
    backgroundColor,
    contentOutline,
  } = props;

  return (
    <div id={id} className={classes.root}>
      <PreviewWidget
        className={classes.previewWidgetRoot}
        skin={backgroundFill}
        backgroundColor={backgroundColor}
        contentOutline={contentOutline}
      >
        <div className={classes.previewWidgetContainer}>
          {previewWidgetContainer}
        </div>
      </PreviewWidget>
    </div>
  );
};

export default createSlotsContainer(WsrPreviewWidget);
