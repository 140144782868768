import type { IGFPPAction } from '@wix/editor-elements-types';
import { getGfppLabel } from '../translations/getGfppLabel';
import { ComponentActions, ComponentPanels } from './builder';

const getGfppAction = (actionType: IGFPPAction['actionType']): IGFPPAction => {
  return {
    actionType,
    label: getGfppLabel(actionType),
  };
};

export function getComponentActions(
  { layoutPanel, managePanel, animationPanel, behaviorsPanel }: ComponentPanels,
  hasStylablePanel: boolean,
): ComponentActions {
  const mainActions: Array<IGFPPAction> = [
    managePanel ? getGfppAction('manage') : getGfppAction('settings'),
  ];
  const enabledActions: Array<IGFPPAction> = [getGfppAction('help')];
  if (hasStylablePanel) {
    enabledActions.unshift(getGfppAction('design'));
  }
  if (layoutPanel) {
    enabledActions.unshift(getGfppAction('layout'));
  }
  if (managePanel) {
    enabledActions.unshift(getGfppAction('settings'));
  }
  if (animationPanel) {
    enabledActions.unshift(getGfppAction('animation'));
  }
  if (behaviorsPanel) {
    enabledActions.unshift(getGfppAction('behaviors'));
  }
  return { mainActions, enabledActions };
}
