import {
  defineLeanCompProps,
  InferExtendedPropsSchema,
} from '@wix/lean-components-infra';
import { OnMouseInPayload } from './corvid/mouseInPropsSDKFactory';
import { OnMouseOutPayload } from './corvid/mouseOutPropsSDKFactory';

export const SIZES: { [key: string]: string } = {
  medium: 'Medium',
  small: 'Small',
  tiny: 'Tiny',
} as const;
const sizes = Object.values(SIZES);

export const WEIGHTS: { [key: string]: string } = {
  thin: 'Regular',
  normal: 'Medium',
} as const;
const weights = Object.values(WEIGHTS);

export const UNDERLINES: { [key: string]: string } = {
  none: 'Never',
  onHover: 'On mouse hover',
  always: 'Always',
} as const;
const underlines = Object.values(UNDERLINES);

export const SKINS: { [key: string]: string } = {
  standard: 'Standard',
  destructive: 'Destructive',
  premium: 'Premium',
  light: 'Light',
  dark: 'Dark',
} as const;
export const skins = Object.values(SKINS);

const props = defineLeanCompProps(
  ({ schemaBuilder }) => ({
    value: schemaBuilder.shortString().default('Text Button'),
    linkTo: schemaBuilder.shortString().default(''),
    prefixIcon: schemaBuilder.svg(),
    suffixIcon: schemaBuilder.svg(),
    disabled: schemaBuilder.boolean().default(false),
    size: schemaBuilder.stringEnum(sizes).default(SIZES.medium),
    weight: schemaBuilder.stringEnum(weights).default(WEIGHTS.thin),
    underline: schemaBuilder.stringEnum(underlines).default(UNDERLINES.none),
    skin: schemaBuilder.stringEnum(skins).default(SKINS.standard),
  }),
  { dmPublish: 'draft' },
)
  .definePanels(({ settingsPanel }) => {
    settingsPanel?.controls.prefixIcon.modify({
      preset: 'WSR_ICONS_MEDIUM',
    });
    settingsPanel?.controls.suffixIcon.modify({
      preset: 'WSR_ICONS_MEDIUM',
    });
    settingsPanel?.controls.size.modify({ controlType: 'radioButtons' });
    settingsPanel?.controls.weight.modify({ controlType: 'radioButtons' });
    settingsPanel?.controls.underline.modify({ controlType: 'radioButtons' });
  })
  .defineSdk(({ sdkMixin }) => ({
    mixins: [sdkMixin.clickable],
  }));

export type TextButtonSDKActions = {
  onMouseIn(payload: OnMouseInPayload): void;
  onMouseOut(payload: OnMouseOutPayload): void;
};

export type IWsrTextButtonProps = InferExtendedPropsSchema<typeof props> &
  TextButtonSDKActions;

export default props;
