import * as React from 'react';
import Input, {
  InputStatus,
  InputSize,
  InputProps,
} from 'wix-style-react/dist/src/Input';
import FormField, { LabelPlacement } from 'wix-style-react/dist/src/FormField';
import type { IWsrInputProps } from '../WsrInput.props';
import {
  PREFIX_AND_SUFFIX_TYPES,
  STATUSES,
  ACTIVE_STATES,
  BORDERS,
  LABEL_POSITIONS,
} from '../WsrInput.props';

const generateIconElement = (
  icon: { svgId?: string },
  text: string,
  type: string,
) => {
  if (type === PREFIX_AND_SUFFIX_TYPES.icon && icon?.svgId) {
    return (
      <Input.IconAffix>
        <div dangerouslySetInnerHTML={{ __html: icon.svgId }} />
      </Input.IconAffix>
    );
  }

  if (type === PREFIX_AND_SUFFIX_TYPES.text) {
    return <Input.Affix>{text}</Input.Affix>;
  }

  return null;
};
/**
 * As a rule of thumb, we use functional components, and use React's hooks and forwardRef for more advanced capabillities.
 * To learn more about React components guidelines, read [the code-guidelines](https://bo.wix.com/pages/editor-elements-docs/docs/development/contributing#code-guidelines)
 */
const WsrInput: React.FC<IWsrInputProps> = props => {
  const {
    id,
    label,
    value,
    placeholder,
    prefixIcon,
    prefixValue,
    prefixType,
    suffixIcon,
    suffixType,
    suffixValue,
    onChange,
    maxLength,
    size,
    labelPosition,
    required,
    infoContent,
    status,
    errorStatusMessage,
    loadingStatusMessage,
    warningStatusMessage,
    showTooltip,
    activeState,
    border,
    updateComponentPropsInViewer,
    onEnterPressed,
  } = props;
  const [charCount, setCharCount] = React.useState(0);
  const [localValue, setLocalValue] = React.useState(value);

  React.useEffect(() => {
    if (maxLength && value.length > maxLength) {
      return;
    }
    setLocalValue(value);
  }, [value, maxLength]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (maxLength && event.target.value.length > maxLength) {
      return;
    }
    updateComponentPropsInViewer({ value: event.target.value });
    setCharCount(event.target.value.length);
    onChange?.({ type: 'change' } as React.ChangeEvent);
  };

  const charCountProp = maxLength === 0 ? undefined : maxLength - charCount;

  const inputStatus = (
    status === STATUSES.none
      ? undefined
      : Object.keys(STATUSES).find(key => STATUSES[key] === status)
  ) as InputStatus;

  const statusMessage =
    status === STATUSES.error
      ? errorStatusMessage
      : status === STATUSES.warning
      ? warningStatusMessage
      : status === STATUSES.loading
      ? loadingStatusMessage
      : undefined;

  const inputState = Object.values(ACTIVE_STATES).find(
    ({ value: currentValue }) => currentValue === activeState,
  );

  const borderValue = Object.keys(BORDERS).find(
    key => BORDERS[key] === border,
  ) as 'standard' | 'round' | 'bottomLine' | 'none';

  const labelPositionValue = Object.keys(LABEL_POSITIONS).find(
    key => LABEL_POSITIONS[key] === labelPosition,
  ) as LabelPlacement;

  const handleEnterPressed: InputProps['onEnterPressed'] = () => {
    return onEnterPressed?.({ type: 'enterPressed' });
  };

  return (
    <div id={id}>
      <FormField
        label={label}
        infoContent={showTooltip && infoContent}
        labelPlacement={labelPositionValue}
        charCount={charCountProp}
        required={required}
      >
        <Input
          maxLength={maxLength}
          value={localValue}
          placeholder={placeholder}
          onChange={handleOnChange}
          status={inputStatus}
          statusMessage={statusMessage}
          size={size.toLowerCase() as InputSize}
          readOnly={inputState?.readOnly}
          disabled={inputState?.disabled}
          prefix={generateIconElement(prefixIcon, prefixValue, prefixType)}
          suffix={generateIconElement(suffixIcon, suffixValue, suffixType)}
          border={borderValue}
          onEnterPressed={handleEnterPressed}
        />
      </FormField>
    </div>
  );
};

export default WsrInput;
