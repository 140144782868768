import {
  PropValidationRule,
  ValidationErrorCode,
  ValidationError,
} from '@wix/editor-elements-types';
import { Schema } from 'yup';

type ValidationModel = (params: Record<string, unknown>) => {
  sdkFormat: object;
  validate: (val: any) => boolean;
  errorCode?: ValidationErrorCode;
};

type TypeValidationModel = Record<string, ValidationModel>;

const NOOP_VALIDATION_MODEL = {
  sdkFormat: {},
  validate: () => true,
};

// Any rule that we want to support in yup validation has to be added here.
// Importnat: Yup marks mandatory fields with required test, while SDK checks requirement with nil type.
const supportedTests: Record<string, TypeValidationModel> = {
  string: {
    length: (params: any) => ({
      sdkFormat: {
        minLength: params.length,
        maxLength: params.length,
      },
      validate: (val: string) => val.length === params.length,
      errorCode: ValidationErrorCode.string_length,
    }),
    min: (params: any) => ({
      sdkFormat: {
        minLength: params.min,
      },
      validate: (val: string) => val.length >= params.min,
      errorCode: ValidationErrorCode.string_min,
    }),
    max: (params: any) => ({
      sdkFormat: {
        maxLength: params.max,
      },
      errorCode: ValidationErrorCode.string_max,
      validate: (val: string) => val.length <= params.max,
    }),
    required: () => NOOP_VALIDATION_MODEL,
  },
  number: {
    min: (params: any) => ({
      sdkFormat: {
        minimum: params.min,
      },
      validate: (val: string) => val >= params.min,
      errorCode: ValidationErrorCode.num_min,
    }),
    max: (params: any) => ({
      sdkFormat: { maximum: params.max },
      validate: (val: string) => val <= params.max,
      errorCode: ValidationErrorCode.num_max,
    }),
    required: () => NOOP_VALIDATION_MODEL,
  },
  boolean: {
    required: () => NOOP_VALIDATION_MODEL,
  },
  // TODO, need to investigate function validation in velo
  function: {
    required: () => NOOP_VALIDATION_MODEL,
  },
  array: {
    required: () => NOOP_VALIDATION_MODEL,
  },
};

export const extractValidationModel = (type: string, testName: string) => {
  if (!(type in supportedTests)) {
    throw new Error(`Yup type: ${type} not supported by SDK validation!`);
  }
  const validationModel =
    supportedTests[type] && supportedTests[type][testName];
  if (!validationModel) {
    throw new Error(
      `Yup validation: ${testName} not supported by SDK validation!`,
    );
  }
  return validationModel;
};

export const extractPropsValidationArgs = (
  fieldSchema: Schema<any>,
): PropValidationRule => {
  const { type, tests, oneOf } = fieldSchema.describe() as any;
  return {
    type,
    tests,
    oneOf,
  };
};

export const applyPropsValidation = (
  { type, tests, oneOf = [] }: PropValidationRule,
  val: any,
): Array<ValidationError> => {
  const testsErrors = tests.reduce((acc, test) => {
    const validationModel = extractValidationModel(type, test.name);
    const { validate, errorCode } = validationModel(test.params);
    return validate(val)
      ? acc
      : [...acc, { code: errorCode, params: test.params } as ValidationError];
  }, [] as Array<ValidationError>);

  if (oneOf.length > 0 && !oneOf.includes(val)) {
    testsErrors.push({
      code: ValidationErrorCode.one_of,
      params: { oneOf: oneOf.join(',') },
    });
  }

  return testsErrors;
};
