import { SchemaFieldDescription } from 'yup';
import {
  SchemaBuilder,
  PropsBuilderObjectSchemaDefinition,
} from '../yupWrapper';
import {
  transformToLeanSchema,
  transformToDocumentSchema,
} from '../leanSchemas';
import { hasValidAdditionalSchemas } from '../leanSchemas/utils';
import { createPropsSchema, ExtendedPropsSchema } from './builder';
import { definePanelsMediator } from './definePanels/definePanels';
import { getValidationSchema } from './sdkValidation';
import { CheckReservedSdkKeys, SdkMixin } from './defineSdk';
import { Metadata } from './metadata';
import { getComponentActions } from './componentActions';
import { getPanels } from './getPanels';

export type DefinePropsOptions = {
  dmPublish: 'draft' | 'GA';
  hasAddPanel?: boolean;
};

export type PropsSchemaBuilderFunction<T extends object> = (args: {
  schemaBuilder: SchemaBuilder;
}) => PropsBuilderObjectSchemaDefinition<T>;

export function defineLeanCompProps<T extends CheckReservedSdkKeys>(
  propsSchemaBuilder: PropsSchemaBuilderFunction<T>,
  options: Partial<DefinePropsOptions> = {
    dmPublish: 'GA',
    hasAddPanel: true,
  },
): ExtendedPropsSchema<T> {
  return createPropsSchema(propsSchemaBuilder, {
    definePanels(overridePanelsCallback) {
      return definePanelsMediator(this, overridePanelsCallback);
    },
    defineSdk(fn) {
      const { fieldsConfig, mixins } = fn({ sdkMixin: SdkMixin });
      return this.meta({
        sdkFieldsConfig: fieldsConfig,
        mixins,
      });
    },
    defineStylable(stylablePanel = {}) {
      return this.meta({
        stylablePanel,
      });
    },
    getStylablePanel() {
      const metadata: Metadata = this.describe().meta || {};
      return metadata?.stylablePanel;
    },
    getSdk() {
      const describe = this.describe();
      const fields = describe.fields || {};

      const meta: Metadata = describe.meta;

      const sdkFieldsConfig = meta?.sdkFieldsConfig || {};

      const mixins = meta?.mixins || null;

      const sdkSchema = Object.fromEntries(
        Object.entries<any>(fields).map(([fieldKey, { type }]) => [
          fieldKey,
          {
            type,
            readonly: sdkFieldsConfig[fieldKey]?.readonly ? true : undefined,
          },
        ]),
      );
      const validationSchema = getValidationSchema(fields);
      return { sdkSchema, validationSchema, mixins } as any;
    },
    hasAddPanel() {
      return (options?.hasAddPanel ?? true) as any;
    },
    transformPropsSchemaToDocumentSchema() {
      const leanSchema = transformToLeanSchema.fromYup(this.fields);
      return transformToDocumentSchema(leanSchema, {
        isDraft: options.dmPublish === 'draft',
      }) as any;
    },
    getAdditionalSchemas() {
      const metaData = this.describe();
      const additionalSchemas = Object.values(metaData.fields).reduce(
        (
          prevSchemas: Record<string, any>,
          currField: SchemaFieldDescription,
        ) => {
          if (hasValidAdditionalSchemas(currField)) {
            Object.entries(
              currField.meta.additionalSchemas as Record<string, any>,
            ).forEach(([name, value]) => {
              prevSchemas[name] = {
                ...value,
                isDraftSchema: options.dmPublish === 'draft',
              };
            });
          }
          return prevSchemas;
        },
        {},
      );

      return Object.keys(additionalSchemas).length > 0
        ? (additionalSchemas as any)
        : null;
    },
    getPanels() {
      return getPanels(this) as any;
    },
    getGfpp() {
      const metadata: Metadata = this.describe().meta || {};
      return getComponentActions(
        getPanels(this),
        !!metadata?.stylablePanel,
      ) as any;
    },
  });
}
