import * as React from 'react';
import Text, {
  TextSkin,
  TextSize,
  TextWeight,
} from 'wix-style-react/dist/src/Text';
import type { IWsrTextProps } from '../WsrText.props';
import { PRESETS, WEIGHTS } from '../WsrText.props';
import { st, classes } from './WsrText.st.css';

const WsrText: React.FC<IWsrTextProps> = props => {
  const { id, value, preset, weight, textAlignment, size, ellipsis, maxLines } =
    props;

  const weightValue = Object.keys(WEIGHTS).find(
    key => WEIGHTS[key] === weight,
  ) as TextWeight;

  const selectedPreset = Object.values(PRESETS).find(
    ({ value: currentPresetValue }) => currentPresetValue === preset,
  );

  return (
    <div
      id={id}
      className={st(classes.root, {
        textAlignment: textAlignment.toLowerCase(),
      })}
    >
      <Text
        weight={weightValue}
        size={size.toLowerCase() as TextSize}
        ellipsis={ellipsis}
        maxLines={maxLines}
        skin={selectedPreset?.presetValue as TextSkin}
        light={selectedPreset?.light}
        secondary={selectedPreset?.secondary}
      >
        {value}
      </Text>
    </div>
  );
};

export default WsrText;
