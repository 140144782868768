import {
  IChangePropsSDKActions,
  IClickPropSDKProps,
  IClickPropsSDKActions,
  IElementPropsSDKProps,
  IElementPropsSDKActions,
  IFocusPropsSDKProps,
  IFocusPropsSDKActions,
  IDisablePropSDKProps,
  IDisablePropSDKActions,
  IAccessibilityPropSDKProps,
} from '@wix/editor-elements-corvid-utils';

export type SdkFieldConfig = {
  readonly?: boolean;
};

export type SdkFieldsConfig<T extends object> = Partial<
  Record<keyof T, SdkFieldConfig>
>;

export enum SdkMixin {
  clickable = 'clickable',
  changeable = 'changeable',
  focusable = 'focusable',
  disableable = 'disableable',
  accessible = 'accessible',
}

export type DefaultSdk = IElementPropsSDKProps & IElementPropsSDKActions;

type ChangebleSdkMixin = IChangePropsSDKActions;
type ClickableSdkMixin = IClickPropSDKProps & IClickPropsSDKActions;
type FocusableSdkMixin = IFocusPropsSDKProps & IFocusPropsSDKActions;
type DisableableSdkMixin = IDisablePropSDKProps & IDisablePropSDKActions;
type AccessibleSdkMixin = IAccessibilityPropSDKProps;

export interface SdkDict {
  [SdkMixin.changeable]: ChangebleSdkMixin;
  [SdkMixin.clickable]: ClickableSdkMixin;
  [SdkMixin.focusable]: FocusableSdkMixin;
  [SdkMixin.disableable]: DisableableSdkMixin;
  [SdkMixin.accessible]: AccessibleSdkMixin;
}

type SdkActions = IElementPropsSDKActions &
  IChangePropsSDKActions &
  IClickPropSDKProps &
  IFocusPropsSDKActions &
  IDisablePropSDKActions;

export type SdkActionUpdateError = {
  [K in keyof SdkActions]?: {
    invalidKey: '⚠️ ⚠️ ⚠️  LEAN COMPONENT SDK ERROR! ⚠️ ⚠️ ⚠️  This key is an callback. You should not change it in the viewer code';
  };
};

type ReservedKeyErrors = {
  [K in keyof ChangebleSdkMixin]?: {
    invalidKey: '⚠️ ⚠️ ⚠️  LEAN COMPONENT SDK ERROR! ⚠️ ⚠️ ⚠️  This key is used by SdkMixin.changeable. Make sure to include it in mixins';
  };
} &
  {
    [K in keyof ClickableSdkMixin]?: {
      invalidKey: '⚠️ ⚠️ ⚠️  LEAN COMPONENT SDK ERROR! ⚠️ ⚠️ ⚠️  This key is used by SdkMixin.clickable. Make sure to include it in mixins';
    };
  } &
  {
    [K in keyof FocusableSdkMixin]?: {
      invalidKey: '⚠️ ⚠️ ⚠️  LEAN COMPONENT SDK ERROR! ⚠️ ⚠️ ⚠️  This key is used by SdkMixin.focusable. Make sure to include it in mixins';
    };
  } &
  {
    [K in keyof DisableableSdkMixin]?: {
      invalidKey: '⚠️ ⚠️ ⚠️  LEAN COMPONENT SDK ERROR! ⚠️ ⚠️ ⚠️  This key is used by SdkMixin.disableable. Make sure to include it in mixins';
    };
  };

export type CheckReservedSdkKeys = object & ReservedKeyErrors;
