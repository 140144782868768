import * as React from 'react';
import { NotificationBanner } from '@wix/wix-base-ui/lib/esm';
import type { IWbuNotificationBannerProps } from '../WbuNotificationBanner.props';
import WbuContainer from '../../WbuContainer/WbuContainer';
import './style/WbuNotificationBanner.global.scss';
import { classes } from './style/WbuNotificationBanner.st.css';

const WbuNotificationBanner: React.FC<IWbuNotificationBannerProps> = props => {
  const {
    id,
    label,
    linkText,
    linkUrl,
    iconTooltipText,
    skin,
    multiline,
    editorType,
  } = props;

  return (
    <WbuContainer id={id} classes={[classes.root]} editorType={editorType}>
      <NotificationBanner
        text={label || 'Notification Banner'}
        skin={skin}
        key={skin}
        link={{
          label: linkText,
          href: linkUrl,
          target: '_blank',
        }}
        icon={
          iconTooltipText
            ? {
                type: skin === 'alert' ? 'alert' : 'info',
                tooltipText: iconTooltipText,
              }
            : undefined
        }
        multiline={multiline}
      />
    </WbuContainer>
  );
};

export default WbuNotificationBanner;
