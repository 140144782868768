import {
  defineLeanCompProps,
  InferExtendedPropsSchema,
} from '@wix/lean-components-infra';
import { OnEnterPressedPayload } from './corvid/enterPressedPropsSDKFactory';

export const PREFIX_AND_SUFFIX_TYPES = {
  none: 'None',
  text: 'Text',
  icon: 'Icon',
};
const prefixAndSuffixTypeOptions = [
  ...([...Object.values(PREFIX_AND_SUFFIX_TYPES)] as const),
];
export type PrefixAndSuffixTypeOptions =
  typeof prefixAndSuffixTypeOptions[number];

const SIZES: { [key: string]: string } = {
  large: 'Large',
  medium: 'Medium',
  small: 'Small',
};

const sizes = [...([...Object.values(SIZES)] as const)];

export const LABEL_POSITIONS: { [key: string]: string } = {
  top: 'On top',
  left: 'Before',
  right: 'After',
};
const labelPositions = [...([...Object.values(LABEL_POSITIONS)] as const)];

export const STATUSES: { [key: string]: string } = {
  none: 'Default',
  error: 'Error',
  warning: 'Warning',
  loading: 'Loading',
};

const statuses = [...([...Object.values(STATUSES)] as const)];

export const ACTIVE_STATES: {
  [key: string]: {
    value: string;
    readOnly: boolean;
    disabled: boolean;
  };
} = {
  ACTIVE: {
    value: 'Active',
    readOnly: false,
    disabled: false,
  },
  READ_ONLY: {
    value: 'Read only',
    readOnly: true,
    disabled: false,
  },
  DISABLED: {
    value: 'Disabled',
    readOnly: false,
    disabled: true,
  },
};

const activeStates = Object.values(ACTIVE_STATES).map(({ value }) => value);

export const BORDERS: { [key: string]: string } = {
  standard: 'Standard',
  round: 'Round',
  bottomLine: 'Underline only',
  none: 'None',
};

const borders = [...([...Object.values(BORDERS)] as const)];

/**
 * The props.ts file is the contract between the React component and Wix ecosystem
 * Through this file you can declare panels, SDKs and behaviours.
 * To learn more about the various capabillities, read [lean components guide](https://bo.wix.com/pages/editor-elements-docs/docs/development/lean-components/index)
 */
const props = defineLeanCompProps(
  ({ schemaBuilder }) => ({
    label: schemaBuilder.shortString().default('Text Input'),
    showTooltip: schemaBuilder.boolean().default(false),
    infoContent: schemaBuilder.longString().default(''),
    placeholder: schemaBuilder.longString().default(''),
    value: schemaBuilder.longString().default(''),
    prefixType: schemaBuilder
      .stringEnum<PrefixAndSuffixTypeOptions>(prefixAndSuffixTypeOptions)
      .default(PREFIX_AND_SUFFIX_TYPES.none),
    prefixIcon: schemaBuilder.svg(),
    prefixValue: schemaBuilder.shortString().default(''),
    suffixType: schemaBuilder
      .stringEnum<PrefixAndSuffixTypeOptions>(prefixAndSuffixTypeOptions)
      .default(PREFIX_AND_SUFFIX_TYPES.none),
    suffixIcon: schemaBuilder.svg(),
    suffixValue: schemaBuilder.shortString().default(''),
    maxLength: schemaBuilder.integer().default(100),
    status: schemaBuilder.stringEnum(statuses).default(STATUSES.none),
    errorStatusMessage: schemaBuilder.longString().default(''),
    warningStatusMessage: schemaBuilder.longString().default(''),
    loadingStatusMessage: schemaBuilder.longString().default(''),
    activeState: schemaBuilder
      .stringEnum(activeStates)
      .default(ACTIVE_STATES.ACTIVE.value),
    required: schemaBuilder.boolean().default(false),
    size: schemaBuilder.stringEnum(sizes).default(SIZES.medium),
    border: schemaBuilder.stringEnum(borders).default(BORDERS.standard),
    labelPosition: schemaBuilder
      .stringEnum(labelPositions)
      .default(LABEL_POSITIONS.top),
  }),
  { dmPublish: 'draft' },
)
  .definePanels(({ settingsPanel, layoutPanel }) => {
    settingsPanel?.controls.omit('labelPosition');
    layoutPanel?.controls.push('labelPosition');
    settingsPanel?.controls.infoContent.isVisible(
      ({ props: { showTooltip } }) => showTooltip,
    );
    settingsPanel?.controls.prefixIcon.isVisible(
      ({ props: { prefixType } }) => prefixType === 'Icon',
    );
    settingsPanel?.controls.prefixValue.isVisible(
      ({ props: { prefixType } }) => prefixType === 'Text',
    );
    settingsPanel?.controls.suffixIcon.isVisible(
      ({ props: { suffixType } }) => suffixType === 'Icon',
    );
    settingsPanel?.controls.suffixValue.isVisible(
      ({ props: { suffixType } }) => suffixType === 'Text',
    );
    settingsPanel?.controls.errorStatusMessage.isVisible(
      ({ props: { status } }) => status === 'Error',
    );
    settingsPanel?.controls.warningStatusMessage.isVisible(
      ({ props: { status } }) => status === 'Warning',
    );
    settingsPanel?.controls.loadingStatusMessage.isVisible(
      ({ props: { status } }) => status === 'Loading',
    );
    settingsPanel?.controls.activeState.modify({
      controlType: 'radioButtons',
    });
    settingsPanel?.controls.prefixType.modify({
      controlType: 'radioButtons',
    });
    settingsPanel?.controls.suffixType.modify({
      controlType: 'radioButtons',
    });
    settingsPanel?.controls.size.modify({
      controlType: 'radioButtons',
    });
    layoutPanel?.controls.labelPosition.modify({
      controlType: 'radioButtons',
    });
    settingsPanel?.controls.prefixIcon.modify({
      preset: 'WSR_ICONS_MEDIUM',
    });
    settingsPanel?.controls.suffixIcon.modify({
      preset: 'WSR_ICONS_MEDIUM',
    });
  })
  .defineSdk(({ sdkMixin }) => ({
    mixins: [sdkMixin.changeable],
  }));

export type InputSDKActions = {
  onEnterPressed(payload: OnEnterPressedPayload): void;
};

export type IWsrInputProps = InferExtendedPropsSchema<typeof props> &
  InputSDKActions;

export default props;
