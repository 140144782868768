import * as React from 'react';
import { CSSProperties } from 'react';
import { Divider } from '@wix/wix-base-ui/lib/esm';
import type { EditorType } from '@wix/lean-components-infra/src/buildtime/viewerComponents/builder';
import { classes, st } from './style/WbuContainer.st.css';

const WbuContainer: React.FC<{
  id: string;
  classes?: Array<string>;
  style?: CSSProperties;
  divider?: 'none' | 'short' | 'long';
  editorType?: EditorType;
}> = props => {
  const {
    id,
    children,
    classes: otherClasses = [],
    style,
    divider = 'none',
    editorType,
  } = props;
  const theme =
    editorType === 'classic' || !editorType ? 'facelift' : editorType;

  return (
    <div
      id={id}
      className={st(classes.root, { theme }, ...otherClasses)}
      style={style}
    >
      {children}
      {divider !== 'none' && <Divider long={divider === 'long'} />}
    </div>
  );
};

export default WbuContainer;
