import {
  mixed,
  string,
  number,
  boolean,
  date,
  array,
  object,
  addMethod,
} from 'yup';
import type {
  Schema,
  InferType,
  ObjectSchema,
  ObjectSchemaDefinition,
  StringSchema,
  ArraySchema,
} from 'yup';
import { PANEL_TYPE_SLOT } from '..';
import { logicalXor } from '../math-utils';
import { createSchema } from './viewerComponents/builder';
import { PANEL_TYPE_MANAGED_OPTIONS } from './constants';
import * as SchemasDefaultConstraints from './leanSchemas/schemasConstraints';
import { basicTransformToDocumentSchema } from './leanSchemas/dsTransformer';
import {
  LeanTypeKey,
  ControlTypeKey,
  CheckReservedSdkKeys,
  PropsSchemaBuilderFunction,
  transformToLeanSchema,
} from './index';

const withLeanSchema = (type: LeanTypeKey) => ({
  leanSchema: { type },
});

const withLeanPanel = (controlType: ControlTypeKey) => ({
  leanPanel: { controlType },
});

const withAdditionalSchemas = (name: string, schema: Record<string, any>) => ({
  additionalSchemas: { [name]: { ...schema } },
});

export const root = {
  mixed, // TODO - delete (after typing refactor)
  string, // TODO - delete (after typing refactor)
  number, // TODO - delete (after typing refactor)
  date, // TODO - delete (after typing refactor)
  array, // TBD
  object, // TBD,
  boolean() {
    return boolean().meta({
      ...withLeanSchema('boolean'),
      ...withLeanPanel('boolean'),
    });
  },
  integer() {
    return number().meta({
      ...withLeanSchema('integer'),
      ...withLeanPanel('integer'),
    });
  },
  double() {
    return number().meta({
      ...withLeanSchema('double'),
      ...withLeanPanel('double'),
    });
  },
  shortString() {
    return string()
      .max(SchemasDefaultConstraints.SmallStringMaxLength)
      .meta({
        ...withLeanSchema('small_string'),
        ...withLeanPanel('small_string'),
      });
  },
  longString() {
    return string()
      .max(SchemasDefaultConstraints.BigStringMaxLength)
      .meta({
        ...withLeanSchema('big_string'),
        ...withLeanPanel('big_string'),
      });
  },
  text() {
    return string()
      .max(SchemasDefaultConstraints.TextMaxLength)
      .meta({
        ...withLeanSchema('text'),
        ...withLeanPanel('text'),
      });
  },
  stringEnum<T extends string>(oneOfEnum: Array<T>) {
    return new mixed<T>({ type: 'string' }).oneOf(oneOfEnum).meta({
      ...withLeanSchema('string_enum'),
      ...withLeanPanel('dropdown'),
    });
  },
  slot() {
    return object({
      $ref: this.shortString().default('SlotRef'),
    }).meta({
      ...withLeanSchema('slot'),
      ...withLeanPanel(PANEL_TYPE_SLOT),
    });
  },
  /**
   * @param {string} itemType
   * This is the array's items schema type.
   * In case no unique schema is required (i.e. propsSchemaBuilder is undefined)
   * this should be undefined as well, and the default schema will be used.
   * @param {PropsSchemaBuilderFunction} propsSchemaBuilder
   * This is the array's items actual schema builder.
   */
  itemArray<T extends CheckReservedSdkKeys>(
    itemType?: string,
    propsSchemaBuilder?: PropsSchemaBuilderFunction<T>,
  ): ArraySchema<any> {
    if (!isValidItemArraySchemaBuilder(itemType, propsSchemaBuilder)) {
      throw new Error(`expected valid itemType and propsSchemaBuilder params`);
    }
    const customItemType = itemType ?? 'defaultItemSchemaType';
    const defaultSchemaBuilder: PropsSchemaBuilderFunction<any> = ({
      schemaBuilder,
    }) => ({
      label: schemaBuilder.shortString(),
      value: schemaBuilder.shortString(),
      disabled: schemaBuilder.boolean().default(false),
      isDefault: schemaBuilder.boolean().default(false),
      unavailable: schemaBuilder.boolean().default(false),
    });
    const additionalSchemas = createSchema(
      propsSchemaBuilder,
      {}, // isDraftSchema not mentioned here on purpose, will be decided later on according parent schema
      defaultSchemaBuilder,
    );
    const additionalLeanSchema = transformToLeanSchema.fromYup(
      additionalSchemas.fields,
    );
    const additionalDSSchemas =
      basicTransformToDocumentSchema(additionalLeanSchema);
    const withIcon = Object.values(additionalSchemas.fields).reduce(
      (prevRes, curField) =>
        curField.meta().leanSchema.type === 'svg' || prevRes,
      false,
    );

    return array()
      .of(additionalSchemas.meta(withLeanSchema('shape')))
      .meta({
        panelType: PANEL_TYPE_MANAGED_OPTIONS,
        withIcon,
        customItemType,
        ...withAdditionalSchemas(customItemType, additionalDSSchemas),
        ...withLeanSchema('array'),
        ...withLeanPanel('options'),
      });
  },
  svg() {
    return object({
      svgId: this.shortString(),
    }).meta({
      ...withLeanSchema('svg'),
      ...withLeanPanel('media_selection'),
    });
  },

  colorPicker() {
    return string().meta({
      ...withLeanSchema('color_picker'),
      ...withLeanPanel('color_picker'),
    });
  },

  fontPicker() {
    return string().meta({
      ...withLeanSchema('font_picker'),
      ...withLeanPanel('font_picker'),
    });
  },
};

const isValidItemArraySchemaBuilder = <T extends CheckReservedSdkKeys>(
  itemType?: string,
  propsSchemaBuilder?: PropsSchemaBuilderFunction<T>,
) => !logicalXor(itemType, propsSchemaBuilder);

export const enrich = addMethod;

export type PropsBuilderObjectSchemaDefinition<T extends object> =
  ObjectSchemaDefinition<T>;

export type PropsBuilderObjectSchema<T extends object> = ObjectSchema<T>;

export type SdkSchema = Record<
  string,
  {
    type: string;
    rules?: object; // TODO add actual rules here for validation
  }
>;

export type SchemaBuilder = typeof root;

export { Schema as PropSchema };

export { InferType as InferPropsType };

export type ExtendSchema<
  StringExtention extends Record<
    string,
    (this: StringSchema, ...args: Array<any>) => StringSchema
  >,
  ObjectExtention extends Record<
    string,
    (this: ObjectSchema, ...args: Array<any>) => ObjectSchema
  >,
> = Omit<typeof root, 'string' | 'object'> & {
  string<T extends string = string>(): StringSchema<T> & StringExtention;
  object<T extends object>(
    fields?: PropsBuilderObjectSchemaDefinition<T>,
  ): PropsBuilderObjectSchema<T> & ObjectExtention;
};
