
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "WbuBackButton2276621578",
      {"classes":{"root":"WbuBackButton2276621578__root","container":"WbuBackButton2276621578__container"},"vars":{},"stVars":{},"keyframes":{}},
      "",
      -1,
      module.id
  );
  