import { LeanPanelHandler } from '@wix/editor-elements-types';
import { Schema } from 'yup';
import { ControlAPI } from './types';

type Handlers = { visibilty?: Array<number>; disabled?: Array<number> };
type RegisterHandler<Props extends object, ReturnType = void> = (
  handler: LeanPanelHandler<Props, ReturnType>,
) => [number, () => void];

export class Control<Props extends object> implements ControlAPI<Props, any> {
  private handlers: Handlers = {};
  private _field: Schema<object>;
  constructor(
    field: Schema<object>,
    private registerHandler: RegisterHandler<Props>,
  ) {
    this._field = field;
  }

  isVisible(handler: LeanPanelHandler<Props, boolean>): () => void {
    return this.register('visibilty', handler);
  }

  isDisabled(handler: LeanPanelHandler<Props, boolean>): () => void {
    return this.register('disabled', handler);
  }
  modify(modifications: any): void {
    const previousMeta = this._field.meta();
    const previousLeanPanel = previousMeta?.leanPanel || {};
    const {
      controlType = previousLeanPanel.controlType,
      ...moreModifications
    } = modifications;

    this._field = this._field.meta({
      ...previousMeta,
      ...moreModifications,
      leanPanel: { ...previousLeanPanel, controlType },
    });
  }

  get visibilityHandlerRefs() {
    return this.handlers.visibilty;
  }

  get disabledHandlerRefs() {
    return this.handlers.disabled;
  }

  get field() {
    return this._field;
  }

  private register(
    type: keyof Handlers,
    handler: LeanPanelHandler<Props, boolean>,
  ): () => void {
    if (!this.handlers[type]) {
      this.handlers[type] = [];
    }
    const [id, unregister] = this.registerHandler(handler);
    this.handlers[type]!.push(id);

    return () => {
      unregister();
      if (this.handlers[type]?.length === 1) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete this.handlers[type];
      } else {
        this.handlers[type]?.splice(this.handlers[type]!.indexOf(id), 1);
      }
    };
  }
}
