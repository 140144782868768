import {
  Base,
  ComponentProps,
  ControlDataType,
  FieldDefinition,
  PropValidationRule,
} from '@wix/editor-elements-types';
import {
  PropsBuilderObjectSchema,
  PropsBuilderObjectSchemaDefinition,
} from '../yupWrapper';
import { ControlSchemaBuilder } from './builder';

type Infer$type<T> = T extends Base<infer P> ? P : never;

export type ControlFactoryConfig = {
  originProp: string;
  enum: Array<string> | null;
  schemaBuilder: ControlSchemaBuilder;
  metaData?: { [key: string]: any };
  tests?: PropValidationRule['tests'];
};

type ControlSchemasFactory<F extends FieldDefinition> = (
  config: ControlFactoryConfig,
) => PropsBuilderObjectSchema<F>;

export type InferControlSchemasFactory<C> = C extends ControlSchemasFactory<
  infer F
>
  ? F
  : never;

export type Typify<
  S extends ControlSchemasFactory<any>,
  D extends ControlDataType,
> = ComponentProps<InferControlSchemasFactory<S>, D>;

export const createControlSchemaFactory = <F extends FieldDefinition>(
  buildControlSchema: (
    config: ControlFactoryConfig,
  ) => Omit<PropsBuilderObjectSchemaDefinition<F>, '$type'>,
  controlId: Infer$type<F>,
): ControlSchemasFactory<F> => {
  const factory: ControlSchemasFactory<F> = config => {
    const { schemaBuilder } = config;
    const fieldsWithType = {
      ...buildControlSchema(config),
      $type: schemaBuilder.string<typeof controlId>().default(controlId),
    } as PropsBuilderObjectSchemaDefinition<F>;
    return schemaBuilder
      .object(fieldsWithType)
      .meta({
        controlId,
      })
      .assignSchemaDefaults(config.originProp);
  };
  // @ts-ignore (WARNING! do no changed, consumed in build time)
  factory.__propsSchemaFactory__ = true;
  return factory;
};
