import * as React from 'react';
import { DrillInListItem, InfoIcon } from '@wix/wix-base-ui/lib/esm';
import { IWbuDrillDownProps } from '../WbuDrillDown.props';
import WbuContainer from '../../WbuContainer/WbuContainer';

import './WbuDrillDown.global.scss';

const WbuDrillDown: React.FC<IWbuDrillDownProps> = props => {
  const { id, label, tooltip, description, iconType, editorType } = props;

  return (
    <WbuContainer id={id} editorType={editorType}>
      <DrillInListItem
        label={label || 'Label Title'}
        description={description}
        contentAfter={
          tooltip.length > 0 && (
            <InfoIcon error={iconType === 'Alert'} small text={tooltip} />
          )
        }
      />
    </WbuContainer>
  );
};

export default WbuDrillDown;
