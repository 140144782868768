import { Schema } from 'yup';
import { PropsBuilderObjectSchema } from '../..';

type Field<Props extends object> = Schema<Props> & { type: string };

export function filterFields<Props extends object>(
  props: PropsBuilderObjectSchema<Props>,
  predicate: (propName: keyof Props, field: Field<Props>) => boolean,
) {
  return Object.entries(props.fields)
    .filter(([propName, field]) =>
      predicate(propName as keyof Props, field as Field<Props>),
    )
    .reduce((acc: any, [propName, field]) => {
      acc[propName] = field;
      return acc;
    }, {});
}
