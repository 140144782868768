import * as React from 'react';
import Tooltip from 'wix-style-react/dist/src/Tooltip';
import IconButton, {
  IconButtonSize,
  IconButtonSkin,
} from 'wix-style-react/dist/src/IconButton';

import type { IWsrIconButtonProps } from '../WsrIconButton.props';
import { usePlatformIcon } from '../../../providers/usePlatformIcon';

const WsrIconButton: React.FC<IWsrIconButtonProps> = props => {
  const {
    id,
    size,
    skin,
    linkUrl,
    tooltipText,
    disabled,
    secondary,
    onClick = () => {},
    onMouseIn,
    onMouseOut,
    ...rest
  } = props;

  const icon = usePlatformIcon(rest.icon);

  const renderIconButton = React.useMemo(() => {
    const addLink = linkUrl ? { href: linkUrl, target: '_blank', as: 'a' } : {};

    const handleOnMouseIn: IWsrIconButtonProps['onMouseEnter'] = () => {
      return onMouseIn?.({ type: 'mouseIn' });
    };

    const handleOnMouseOut: IWsrIconButtonProps['onMouseLeave'] = () => {
      return onMouseOut?.({ type: 'mouseOut' });
    };

    return (
      <IconButton
        disabled={disabled}
        onMouseEnter={handleOnMouseIn}
        onMouseLeave={handleOnMouseOut}
        size={size.toLowerCase() as IconButtonSize}
        priority={secondary ? 'secondary' : undefined}
        skin={skin.toLowerCase() as IconButtonSkin}
        onClick={onClick}
        {...addLink}
        as={linkUrl ? 'a' : 'button'}
      >
        {icon}
      </IconButton>
    );
  }, [
    linkUrl,
    skin,
    size,
    icon,
    secondary,
    disabled,
    onClick,
    onMouseIn,
    onMouseOut,
  ]);

  return (
    <div id={id}>
      {tooltipText ? (
        <Tooltip content={tooltipText}>{renderIconButton}</Tooltip>
      ) : (
        renderIconButton
      )}
    </div>
  );
};

export default WsrIconButton;
