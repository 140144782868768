import type { StringSchema } from 'yup';
import { getPropTranslationKey } from '../translations/getPropTranslationKey';
import {
  enrich,
  ExtendSchema,
  PropsBuilderObjectSchema,
  root,
} from '../yupWrapper';

function translate(this: StringSchema) {
  return this.meta({
    translate: true,
  });
}

function assignSchemaDefaults<T extends object>(
  this: PropsBuilderObjectSchema<T>,
  compProp: string,
) {
  this.withMutation(schema => {
    Object.keys(schema.fields)
      .filter((controlProp: string) => {
        // 🤷‍♂️ fucking schema typing, right?
        return (schema.fields as any)[controlProp].meta()?.translate;
      })
      .forEach((controlProp: string) => {
        // 🤷‍♂️ fucking schema typing, right?
        const { controlId } = schema.meta() as any;
        (schema.fields as any)[controlProp] = (schema.fields as any)[
          controlProp
        ].default(
          getPropTranslationKey({
            compProp,
            controlId,
            controlProp,
          }),
        );
      });
  });
  return this;
}

export type ControlSchemaBuilder = ExtendSchema<
  {
    translate: typeof translate;
  },
  {
    assignSchemaDefaults: typeof assignSchemaDefaults;
  }
>;

export const createControlSchemaBuilder = <T extends object>() => {
  enrich<PropsBuilderObjectSchema<T>>(
    root.object,
    'assignSchemaDefaults',
    assignSchemaDefaults,
  );
  enrich<StringSchema>(root.string, 'translate', translate);
  return root as unknown as ControlSchemaBuilder;
};
