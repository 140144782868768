import * as React from 'react';
import Button from 'wix-style-react/dist/src/Button';
import Loader from 'wix-style-react/dist/src/Loader';

import type { IWsrButtonProps } from '../WsrButton.props';
import { usePlatformIcon } from '../../../providers/usePlatformIcon';

const BUTTON_PRIORITIES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
} as const;

const WsrButton: React.FC<IWsrButtonProps> = props => {
  const {
    id,
    value,
    linkTo,
    disabled,
    loading,
    skin,
    size,
    secondary,
    onMouseIn,
    onMouseOut,
    onClick = () => {},
    ...rest
  } = props;

  const addLink = linkTo ? { href: linkTo, target: '_blank' } : {};

  const prefix = usePlatformIcon(rest.prefixIcon);
  const suffix = usePlatformIcon(rest.suffixIcon);

  const priority = secondary
    ? BUTTON_PRIORITIES.SECONDARY
    : BUTTON_PRIORITIES.PRIMARY;

  const handleMouseIn: React.MouseEventHandler<HTMLElement> = () =>
    onMouseIn?.({ type: 'mouseIn' });

  const handleMouseOut: React.MouseEventHandler<HTMLElement> = () =>
    onMouseOut?.({ type: 'mouseOut' });

  return (
    <div id={id}>
      <Button
        {...addLink}
        as={linkTo ? 'a' : undefined}
        skin={skin}
        disabled={disabled}
        size={size}
        priority={priority}
        onClick={onClick}
        prefixIcon={prefix}
        suffixIcon={suffix}
        onMouseEnter={handleMouseIn}
        onMouseLeave={handleMouseOut}
      >
        {loading ? <Loader size="tiny" /> : value}
      </Button>
    </div>
  );
};

export default WsrButton;
