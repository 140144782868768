import * as React from 'react';
import {
  Composites,
  ColorPickerInput,
  OpacityPicker,
  TextLabel,
  InfoIcon,
} from '@wix/wix-base-ui/lib/esm';
import type { IWbuColorPickerProps } from '../WbuColorPicker.props';
import WbuContainer from '../../WbuContainer/WbuContainer';
import './WbuColorPicker.global.scss';

const WbuColorPicker: React.FC<IWbuColorPickerProps> = props => {
  const {
    id,
    label,
    tooltip,
    color,
    opacity,
    onChange,
    onClick,
    updateComponentPropsInViewer,
    showPercentage,
    opacitySliderEnabled,
    divider,
  } = props;

  const _onChange = (opacityValue: number) => {
    updateComponentPropsInViewer({ opacity: opacityValue });
    onChange?.({ type: 'change' } as React.ChangeEvent);
  };

  return (
    <WbuContainer id={id} divider={divider}>
      <Composites.ColorPickerInputLabeled>
        <TextLabel type="T02" value={label} shouldTranslate={false} />
        {tooltip && <InfoIcon text={tooltip} shouldTranslate={false} />}
        <Composites.ColorPickerInput>
          <OpacityPicker
            value={opacity}
            onChange={(opacityValue: number) => _onChange(opacityValue)}
            {...(opacitySliderEnabled
              ? { opacityGradientColor: color }
              : { customFillGradient: '#116DFF' })}
            unit={showPercentage ? 'percent' : ''}
          />
          <ColorPickerInput value={color} opacity={opacity} onClick={onClick} />
        </Composites.ColorPickerInput>
      </Composites.ColorPickerInputLabeled>
    </WbuContainer>
  );
};

export default WbuColorPicker;
