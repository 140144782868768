
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "SettingsPanelContainer1991984522",
      {"classes":{"root":"SettingsPanelContainer1991984522__root"},"vars":{},"stVars":{},"keyframes":{}},
      "",
      -1,
      module.id
  );
  