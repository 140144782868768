import {
  withCompInfo,
  withStateRefs,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

const props = withCompInfo<any, any>()(['compData'], ({ compData }) => {
  return {
    ...{
      selectedFont: 'arial',
      colorPickerInput: '#EEE',
      numericInput: 0,
      slider: 1,
      thumbnails: {},
      toggleSwitch: false,
      radioButtons: 'Options 1',
      dropdown: 'Options 1',
      options: [],
      longString: 'LongString',
      shortString: 'ShortString',
    },
    ...compData,
  };
});

export const stateRefs = withStateRefs<any>()([], ({ refApi }) => ({
  editorType: refApi.env.editorType(),
}));

export default createComponentMapperModel({
  props,
  stateRefs,
});
