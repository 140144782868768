import * as React from 'react';
import {
  TextInputMultiline,
  Composites,
  TextLabel,
  InfoIcon,
} from '@wix/wix-base-ui/lib/esm';
import type { IWbuMultilineTextInputProps } from '../WbuMultilineTextInput.props';
import WbuContainer from '../../WbuContainer/WbuContainer';

import './WbuMultilineTextInput.global.scss';

const WbuMultilineTextInput: React.FC<IWbuMultilineTextInputProps> = props => {
  const {
    id,
    placeholder,
    readOnly,
    autoFocus,
    maxLength,
    label,
    value,
    onChange,
    closeOnEnterKeyPress,
    selectTextOnFocus,
    validationMessage,
    regexPattern,
    usePatternValidation,
    tooltip,
    divider,
    updateComponentPropsInViewer,
  } = props;

  const invalidMessageCheckFallback = 'Error...';

  const _onChange = (val: string) => {
    updateComponentPropsInViewer({ value: val });
    onChange?.({ type: 'change' } as React.ChangeEvent);
  };

  const invalidMessageCheck =
    usePatternValidation && validationMessage ? validationMessage : '';
  const isValidCheck = usePatternValidation
    ? !!value.match(new RegExp(regexPattern))
    : true;

  // We add global style this way because `global.scss` files are not properly loaded for
  // this component.
  const Style = () => {
    const css = `
    .composite-with-info-icon > .has-tooltip.info-icon-tooltip {
      top: 0;
    }`;
    return <style dangerouslySetInnerHTML={{ __html: css }} />;
  };

  return (
    <WbuContainer id={id} divider={divider}>
      <Composites.TextInputMultilineLabeled>
        <Style />
        {tooltip && <InfoIcon text={tooltip} />}
        {label && <TextLabel type="T02" value={label} />}
        <TextInputMultiline
          placeholder={placeholder}
          isReadOnly={readOnly}
          focus={autoFocus}
          value={value}
          maxLength={maxLength === 0 ? 99999 : maxLength}
          blurOnEnterKey={closeOnEnterKeyPress}
          onChange={_onChange}
          selectOnFocus={selectTextOnFocus}
          invalidMessage={invalidMessageCheck || invalidMessageCheckFallback}
          isValid={isValidCheck}
        />
      </Composites.TextInputMultilineLabeled>
    </WbuContainer>
  );
};

export default WbuMultilineTextInput;
