import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import type {
  IComponentPreviewWrapper,
  PreviewWrapperProps,
  DefaultCompPlatformProps,
  PreviewTooltipProps,
} from '@wix/editor-elements-types';
import React from 'react';

import { usePreviewState, useResetComponent } from './hooks';
import { BlockingLayer } from './BlockingLayer';

type Props = DefaultCompPlatformProps & {
  ref?: React.Ref<any>;
};

export function withComponentPreview<TCompProps extends Props = Props>(
  WrappedComponent: React.ComponentType<TCompProps>,
): IComponentPreviewWrapper<TCompProps> {
  return React.forwardRef<any, any>(
    (
      {
        previewWrapperProps = {},
        ...viewerProps
      }: PreviewWrapperProps<TCompProps>,
      ref,
    ) => {
      const { id } = viewerProps;
      const {
        compPreviewState,
        blockingLayer: blockingLayerProps = { enabled: false },
        shouldResetComponent,
      } = previewWrapperProps;

      usePreviewState(viewerProps.id, compPreviewState);

      const key = useResetComponent({
        id,
        shouldResetComponent,
      });

      const canDisplayTooltip = () => {
        return (
          // showTooltip is injected by a feature from thunderbolt
          // https://github.com/wix-private/thunderbolt/blob/master/packages/ds-feature-components-preview-tooltip
          (viewerProps as unknown as PreviewTooltipProps).showTooltip &&
          blockingLayerProps.tooltipText
        );
      };

      return (
        <>
          <WrappedComponent
            {...(viewerProps as TCompProps)}
            key={key}
            ref={ref}
          />
          {blockingLayerProps.enabled && (
            <BlockingLayer
              {...(canDisplayTooltip() && {
                onClick: ev => {
                  (viewerProps as unknown as PreviewTooltipProps).showTooltip(
                    ev.currentTarget.getBoundingClientRect(),
                    blockingLayerProps.tooltipText!,
                  );
                },
              })}
              targetId={viewerProps.id}
            />
          )}
        </>
      );
    },
  );
}

export const createPreviewFallback = (WrappedComponent: any) =>
  createComponentPreviewEntry(withComponentPreview(WrappedComponent));
