import { SchemaFieldDescription } from 'yup';
import { PANEL_TYPE_MANAGED_OPTIONS } from '../constants';
import { Metadata } from './metadata';
import { extractValidationModel } from './propsValidations';

const MANAGE_OPTIONS_VALIDATION = {
  type: ['array', 'nil'],
  items: {
    type: ['object'],
    properties: {
      value: {
        type: ['string', 'nil'],
      },
      label: {
        type: ['string', 'nil'],
      },
      disabled: {
        type: ['boolean', 'nil'],
      },
    },
  },
};

// TODO, would like to have a more generic type definition than checking panelType in meta
const isManageOptions = (meta: Metadata) => {
  if (!meta) {
    return false;
  }
  return meta.panelType === PANEL_TYPE_MANAGED_OPTIONS;
};

function getFieldValidations({
  type,
  tests,
  oneOf,
  meta,
}: {
  type: string;
  tests: Array<{ name: string; params: { [k: string]: any } }>;
  oneOf?: Array<string>;
  meta: Metadata;
}) {
  if (isManageOptions(meta)) {
    return MANAGE_OPTIONS_VALIDATION;
  }

  const fieldValidations: any = tests.reduce((acc, test) => {
    const validationModel = extractValidationModel(type, test.name);
    const { sdkFormat } = validationModel(test.params);
    return {
      ...acc,
      ...sdkFormat,
    };
  }, {});

  // TODO when we have more requirements we might think about making fields optional/mandatory
  fieldValidations.type = [type];

  if (oneOf) {
    fieldValidations.enum = oneOf;
  }

  return fieldValidations;
}

export function getValidationSchema(
  fields: Record<string, SchemaFieldDescription>,
) {
  const validationSchemaProperties = Object.fromEntries(
    Object.entries<any>(fields)
      .map(([fieldKey, fieldData]) => [
        fieldKey,
        getFieldValidations(fieldData),
      ])
      .filter(([_fieldKey, fieldValidations]) => !!fieldValidations),
  );

  const validationSchema =
    Object.keys(validationSchemaProperties).length > 0
      ? {
          type: ['object'],
          properties: validationSchemaProperties,
        }
      : null;

  return validationSchema;
}
