import * as React from 'react';
import {
  Composites,
  TextLabel,
  CornerRadiusInput,
  InfoIcon,
} from '@wix/wix-base-ui/lib/esm';

import WbuContainer from '../../WbuContainer/WbuContainer';
import { IWbuCornerRadiusInputProps } from '../WbuCornerRadiusInput.props';

import './WbuCornerRadiusInput.global.scss';

const WbuCornerRadiusInput: React.FC<IWbuCornerRadiusInputProps> = props => {
  const {
    id,
    label,
    tooltipText,
    value,
    minValue,
    maxValue,
    linkedBounds,
    onChange,
    updateComponentPropsInViewer,
    divider,
    editorType,
  } = props;

  const _onChange = (val: number) => {
    updateComponentPropsInViewer({ value: val });
    onChange?.({ type: 'change' } as React.ChangeEvent);
  };

  return (
    <WbuContainer id={id} divider={divider} editorType={editorType}>
      <Composites.CornerRadiusInputLabeled>
        <InfoIcon text={tooltipText} />
        <TextLabel type="T02" value={label} />
        <CornerRadiusInput
          value={`${value}`}
          isInitiallyLocked={linkedBounds}
          min={minValue}
          max={maxValue}
          onChange={_onChange}
        />
      </Composites.CornerRadiusInputLabeled>
    </WbuContainer>
  );
};

export default WbuCornerRadiusInput;
