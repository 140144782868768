import {
  defineLeanCompProps,
  InferExtendedPropsSchema,
} from '@wix/lean-components-infra';
import type { DefaultContainerProps } from '@wix/editor-elements-types';
import { OnCloseButtonClickPayload } from './corvid/closeButtonClickPropsSDKFactory';
import { OnCheckboxTogglePayload } from './corvid/checkboxTogglePropsSDKFactory';
import { OnPrimaryButtonClickPayload } from './corvid/primaryButtonClickPropsSDKFactory';
import { OnSecondaryButtonClickPayload } from './corvid/secondaryButtonClickPropsSDKFactory';
import { OnHelpButtonClickPayload } from './corvid/helpButtonClickPropsSDKFactory';

export const THEME_OPTIONS = {
  standard: 'Standard',
  premium: 'Premium',
  destructive: 'Destructive',
};

export const HEADER_DIVIDER_VISIBILITY = {
  auto: 'Auto',
  always: 'Always',
  never: 'Never',
};

const props = defineLeanCompProps(
  ({ schemaBuilder }) => ({
    theme: schemaBuilder
      .stringEnum(Object.values(THEME_OPTIONS))
      .default(THEME_OPTIONS.standard),
    showHeader: schemaBuilder.boolean().default(true),
    title: schemaBuilder.longString().default('Custom modal title'),
    subtitle: schemaBuilder
      .longString()
      .default('This is modal subtitle. You can remove it if you want.'),
    showHeaderDivider: schemaBuilder
      .stringEnum(Object.values(HEADER_DIVIDER_VISIBILITY))
      .default(HEADER_DIVIDER_VISIBILITY.always),
    removeContentPadding: schemaBuilder.boolean().default(false),
    showFooter: schemaBuilder.boolean().default(true),
    primaryButtonText: schemaBuilder.shortString().default('Save'),
    primaryButtonLink: schemaBuilder.shortString().default(''),
    primaryButtonPrefix: schemaBuilder.svg(),
    primaryButtonSuffix: schemaBuilder.svg(),
    primaryButtonDisabled: schemaBuilder.boolean().default(false),
    addSecondaryAction: schemaBuilder.boolean().default(true),
    secondaryButtonText: schemaBuilder.shortString().default('Cancel'),
    secondaryButtonLink: schemaBuilder.shortString().default(''),
    secondaryButtonPrefix: schemaBuilder.svg(),
    secondaryButtonSuffix: schemaBuilder.svg(),
    sideActionsCheckbox: schemaBuilder.boolean().default(true),
    sideActionsCheckboxTitle: schemaBuilder
      .longString()
      .default('Send me a copy via an email'),
    showFooterDivider: schemaBuilder
      .stringEnum(Object.values(HEADER_DIVIDER_VISIBILITY))
      .default(HEADER_DIVIDER_VISIBILITY.always),
    footnote: schemaBuilder.boolean().default(true),
    footnoteContent: schemaBuilder
      .longString()
      .default('By proceeding, you agree to our privacy policy.'),
    footnoteLink: schemaBuilder.boolean().default(true),
    footnoteLinkText: schemaBuilder
      .longString()
      .default('Learn more about terms of use'),
    footnoteLinkUrl: schemaBuilder.shortString().default(''),
    helpButton: schemaBuilder.boolean().default(false),
    helpButtonUrl: schemaBuilder.shortString().default(''),
    contentContainer: schemaBuilder.slot(),
    width: schemaBuilder.integer().default(600),
    height: schemaBuilder.integer().default(480),
  }),
  { dmPublish: 'draft' },
)
  .defineSdk(({ sdkMixin }) => ({
    mixins: [sdkMixin.changeable],
  }))
  .definePanels(({ settingsPanel, layoutPanel }) => {
    layoutPanel?.controls.push('width', 'height');
    settingsPanel?.controls.omit('width', 'height');
    settingsPanel?.controls.title.isVisible(
      ({ props: { showHeader } }) => showHeader,
    );
    settingsPanel?.controls.subtitle.isVisible(
      ({ props: { showHeader } }) => showHeader,
    );
    settingsPanel?.controls.showHeaderDivider.isVisible(
      ({ props: { showHeader } }) => showHeader,
    );
    settingsPanel?.controls.primaryButtonText.isVisible(
      ({ props: { showFooter } }) => showFooter,
    );
    settingsPanel?.controls.primaryButtonDisabled.isVisible(
      ({ props: { showFooter } }) => showFooter,
    );
    settingsPanel?.controls.primaryButtonLink.isVisible(
      ({ props: { showFooter } }) => showFooter,
    );
    settingsPanel?.controls.primaryButtonPrefix.isVisible(
      ({ props: { showFooter } }) => showFooter,
    );
    settingsPanel?.controls.primaryButtonSuffix.isVisible(
      ({ props: { showFooter } }) => showFooter,
    );
    settingsPanel?.controls.addSecondaryAction.isVisible(
      ({ props: { showFooter } }) => showFooter,
    );
    settingsPanel?.controls.secondaryButtonText.isVisible(
      ({ props: { showFooter, addSecondaryAction } }) =>
        showFooter && addSecondaryAction,
    );
    settingsPanel?.controls.secondaryButtonLink.isVisible(
      ({ props: { showFooter, addSecondaryAction } }) =>
        showFooter && addSecondaryAction,
    );
    settingsPanel?.controls.secondaryButtonPrefix.isVisible(
      ({ props: { showFooter, addSecondaryAction } }) =>
        showFooter && addSecondaryAction,
    );
    settingsPanel?.controls.secondaryButtonSuffix.isVisible(
      ({ props: { showFooter, addSecondaryAction } }) =>
        showFooter && addSecondaryAction,
    );
    settingsPanel?.controls.sideActionsCheckboxTitle.isVisible(
      ({ props: { sideActionsCheckbox } }) => sideActionsCheckbox,
    );
    settingsPanel?.controls.footnoteContent.isVisible(
      ({ props: { footnote } }) => footnote,
    );
    settingsPanel?.controls.footnoteLink.isVisible(
      ({ props: { footnote } }) => footnote,
    );
    settingsPanel?.controls.footnoteLinkText.isVisible(
      ({ props: { footnote, footnoteLink } }) => footnote && footnoteLink,
    );
    settingsPanel?.controls.footnoteLinkUrl.isVisible(
      ({ props: { footnote, footnoteLink } }) => footnote && footnoteLink,
    );
    settingsPanel?.controls.helpButtonUrl.isVisible(
      ({ props: { helpButton } }) => helpButton,
    );
    settingsPanel?.controls.primaryButtonPrefix.modify({
      preset: 'WSR_ICONS_SMALL',
    });
    settingsPanel?.controls.primaryButtonSuffix.modify({
      preset: 'WSR_ICONS_SMALL',
    });
    settingsPanel?.controls.secondaryButtonPrefix.modify({
      preset: 'WSR_ICONS_SMALL',
    });
    settingsPanel?.controls.secondaryButtonSuffix.modify({
      preset: 'WSR_ICONS_SMALL',
    });
  });

export type CustomModalLayoutSDKActions = {
  onCloseButtonClick(payload: OnCloseButtonClickPayload): void;
  onCheckboxToggle(payload: OnCheckboxTogglePayload): void;
  onPrimaryButtonClick(payload: OnPrimaryButtonClickPayload): void;
  onSecondaryButtonClick(payload: OnSecondaryButtonClickPayload): void;
  onHelpButtonClick(payload: OnHelpButtonClickPayload): void;
};

export type IWsrCustomModalLayoutProps = InferExtendedPropsSchema<
  typeof props
> &
  DefaultContainerProps &
  CustomModalLayoutSDKActions;

export default props;
