import * as React from 'react';
import Heading, { Size } from 'wix-style-react/dist/src/Heading';
import type { IWsrHeadingProps } from '../WsrHeading.props';
import { SIZES } from '../WsrHeading.props';
import { st, classes } from './WsrHeading.st.css';

const WsrHeading: React.FC<IWsrHeadingProps> = props => {
  const { id, value, size, invertColor, textAlignment } = props;
  const sizeValue = Object.keys(SIZES).find(key => SIZES[key] === size) as Size;

  return (
    <div
      id={id}
      className={st(classes.root, {
        textAlignment: textAlignment.toLowerCase(),
      })}
    >
      <Heading light={invertColor} size={sizeValue}>
        {value}
      </Heading>
    </div>
  );
};

export default WsrHeading;
