import * as React from 'react';
import {
  Composites,
  TextLabel,
  RichText,
  Illustration,
} from '@wix/wix-base-ui/lib/esm';
import WbuContainer from '../../WbuContainer/WbuContainer';
import { IWbuEmptyStateProps } from '../WbuEmptyState.props';
import RenderSvg from '../RenderSvg/RenderSvg';
import { TestIds } from '../constants';
import './WbuEmptyState.global.scss';

const WbuEmptyState: React.FC<IWbuEmptyStateProps> = props => {
  const {
    id,
    label,
    description,
    textButtonLabel,
    url,
    illustration,
    editorType,
  } = props;

  const link = (
    <a
      data-hook={TestIds.richTextLink}
      href={url}
      target="_blank"
      rel="noreferrer"
    >
      {textButtonLabel}
    </a>
  );

  return (
    <WbuContainer id={id} editorType={editorType}>
      <Composites.RichTextWithIllustrationVertical>
        <Illustration>
          {!!illustration && <RenderSvg svgId={illustration.svgId} />}
        </Illustration>
        <Composites.RichTextLabeled>
          <TextLabel value={label} type="T09" />
          <RichText type="T02">
            {description}
            <br />
            {link}
          </RichText>
        </Composites.RichTextLabeled>
      </Composites.RichTextWithIllustrationVertical>
    </WbuContainer>
  );
};

export default WbuEmptyState;
