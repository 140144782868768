import * as React from 'react';
import { useEffect } from 'react';
import NumberInput, {
  NumberInputProps,
} from 'wix-style-react/dist/src/NumberInput';
import FormField, { LabelPlacement } from 'wix-style-react/dist/src/FormField';
import Input, { InputStatus, InputSize } from 'wix-style-react/dist/src/Input';
import {
  STATUSES,
  PREFIX_AND_SUFFIX_TYPES,
  ACTIVE_STATES,
  BORDERS,
  LABEL_POSITIONS,
} from '../WsrNumberInput.props';

import type { IWsrNumberInputProps } from '../WsrNumberInput.props';

const generateIconElement = (
  icon: { svgId?: string },
  text: string,
  type: string,
) => {
  if (type === PREFIX_AND_SUFFIX_TYPES.icon && icon?.svgId) {
    return (
      <Input.IconAffix>
        <div dangerouslySetInnerHTML={{ __html: icon.svgId }} />
      </Input.IconAffix>
    );
  }

  if (type === PREFIX_AND_SUFFIX_TYPES.text) {
    return <Input.Affix>{text}</Input.Affix>;
  }

  return null;
};

const WsrNumberInput: React.FC<IWsrNumberInputProps> = props => {
  const {
    id,
    value: givenValue,
    label,
    showTooltip,
    infoContent,
    labelPlacement,
    placeholderText,
    minAndMaxValues,
    minimumValue,
    maximumValue,
    prefixType,
    prefixIcon,
    prefixValue,
    suffixType,
    suffixIcon,
    suffixValue,
    stepSize,
    status,
    errorStatusMessage,
    loadingStatusMessage,
    warningStatusMessage,
    activeState,
    required,
    size,
    border,
    invalidMessage,
    onChange,
    onKeyPress,
    onInvalid,
    onPaste,
  } = props;

  const [value, setValue] = React.useState<number | string | undefined>(
    givenValue,
  );

  useEffect(() => {
    setValue(givenValue);
  }, [givenValue]);

  const handleInvalid: NumberInputProps['onInvalid'] = invalidValue => {
    return onInvalid?.({ type: 'invalid', value: invalidValue });
  };

  const handleChange: NumberInputProps['onChange'] = (
    newValue,
    stringValue,
  ) => {
    return onChange?.({ type: 'change', value: newValue, stringValue });
  };

  const handlePaste: NumberInputProps['onPaste'] = event => {
    return onPaste?.({
      type: 'paste',
      clipboardData: event.clipboardData.getData('Text'),
    });
  };

  const handleKeyDown: NumberInputProps['onKeyDown'] = event => {
    return onKeyPress?.({ type: 'keyPress', key: event.key });
  };

  const setNewValue = (newValue: number | null, stringValue: string) => {
    setValue(newValue === null ? '' : newValue);
    handleChange(newValue, stringValue);
  };

  const inputStatus = (
    status === STATUSES.none
      ? undefined
      : Object.keys(STATUSES).find(key => STATUSES[key] === status)
  ) as InputStatus;

  const statusMessage =
    status === STATUSES.error
      ? errorStatusMessage
      : status === STATUSES.warning
      ? warningStatusMessage
      : status === STATUSES.loading
      ? loadingStatusMessage
      : undefined;

  const inputState = Object.values(ACTIVE_STATES).find(
    ({ value: currentValue }) => currentValue === activeState,
  );

  const borderValue = Object.keys(BORDERS).find(
    key => BORDERS[key] === border,
  ) as 'standard' | 'round' | 'bottomLine' | 'none';

  const labelPositionValue = Object.keys(LABEL_POSITIONS).find(
    key => LABEL_POSITIONS[key] === labelPlacement,
  ) as LabelPlacement;

  return (
    <div id={id}>
      <FormField
        label={label}
        infoContent={showTooltip && infoContent}
        labelPlacement={labelPositionValue}
        required={required}
      >
        <NumberInput
          value={value}
          onChange={setNewValue}
          placeholder={placeholderText}
          min={minAndMaxValues ? minimumValue : undefined}
          max={minAndMaxValues ? maximumValue : undefined}
          prefix={generateIconElement(prefixIcon, prefixValue, prefixType)}
          suffix={generateIconElement(suffixIcon, suffixValue, suffixType)}
          step={stepSize}
          status={inputStatus}
          statusMessage={statusMessage}
          readOnly={inputState?.readOnly}
          disabled={inputState?.disabled}
          size={size.toLowerCase() as InputSize}
          invalidMessage={invalidMessage}
          border={borderValue}
          onInvalid={handleInvalid}
          onPaste={handlePaste}
          onKeyDown={handleKeyDown}
        />
      </FormField>
    </div>
  );
};

export default WsrNumberInput;
