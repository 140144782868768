import type { IComponentController } from '@wix/editor-elements-types';
import type { IWbuLeftTextButtonProps } from '../../../components/WbuLeftTextButton/WbuLeftTextButton.props';

const mapActionsToProps = ({ updateProps }) => ({
  updateComponentPropsInViewer: (props: Partial<IWbuLeftTextButtonProps>) => {
    updateProps(props);
  },
});

const mapStateToProps = ({ editorType }) => ({
  editorType,
});

const compController: IComponentController = {
  mapStateToProps,
  mapActionsToProps,
};

export default compController;
