import { Schema } from 'yup';
import {
  PANEL_TYPE_MANAGED_OPTIONS,
  PropsBuilderObjectSchema,
  PANEL_TYPE_SLOT,
} from '../..';
import { SuportedPanels } from './types';
import { filterFields } from './utils';

// TODO - normalize the way we define the panelType and make a unified way to determine field's type
const isManageOptions = (field: Schema<object>) =>
  !field.meta() ? false : field.meta().panelType === PANEL_TYPE_MANAGED_OPTIONS;

const isSlot = (field: Schema<object>) =>
  !field.meta()
    ? false
    : field.meta().leanPanel.controlType === PANEL_TYPE_SLOT;

export const supportedPanels: SuportedPanels = [
  {
    key: 'settingsPanel',
    gfppActionType: 'settings',
    defaultPropsFilter: (props: PropsBuilderObjectSchema<object>) => {
      props.fields = filterFields(
        props,
        (name, field) =>
          field.type !== 'function' &&
          !isManageOptions(field) &&
          !isSlot(field),
      );
      return props;
    },
  },
  {
    key: 'manageOptionsPanel',
    gfppActionType: 'manage',
    defaultPropsFilter: (props: PropsBuilderObjectSchema<object>) => {
      props.fields = filterFields(
        props,
        (name, field) => field.type !== 'function' && isManageOptions(field),
      );
      return props;
    },
  },
  {
    key: 'layoutPanel',
    gfppActionType: 'layout',
  },
];
