import {
  withCompInfo,
  withStateRefs,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

const props = withCompInfo<any, any>()(['compData'], ({ compData }) => {
  return {
    ...{
      divider: 'long',
      maxValue: 100,
      minValue: 0,
      stepControls: false,
      steps: 1,
      numberOfFractions: 2,
      unitSuffix: 'None',
      value: 0,
      title: '',
    },
    ...compData,
  };
});

export const stateRefs = withStateRefs<any>()([], ({ refApi }) => ({
  editorType: refApi.env.editorType(),
}));

export default createComponentMapperModel({
  props,
  stateRefs,
});
