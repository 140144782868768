import * as React from 'react';
import { ManagedOptionDataType } from '@wix/editor-elements-types';
import { HorizontalTabs } from '@wix/wix-base-ui/lib/esm';
import { IWbuHorizontalTabsProps, TabsStyle } from '../WbuHorizontalTabs.props';
import './WbuHorizontalTabs.global.scss';
import WbuContainer from '../../WbuContainer/WbuContainer';

const WbuHorizontalTabs: React.FC<IWbuHorizontalTabsProps> = props => {
  const {
    id,
    options,
    tabsStyle,
    updateComponentPropsInViewer,
    onChange,
    editorType,
  } = props;

  const _onChange = (selectedOptionValue: string) => {
    const newOptions = options.map(option => {
      if (option.value === selectedOptionValue) {
        return { ...option, isDefault: true };
      } else {
        return { ...option, isDefault: false };
      }
    });
    updateComponentPropsInViewer({
      options: newOptions,
    });

    onChange?.({ type: 'change' } as React.ChangeEvent);
  };
  const baseUITypes: Record<TabsStyle, string> = {
    Folder: 'tabbed',
    Arrowed: 'arrowed',
    'Arrowed Without a Line': 'arrowedNoLine',
  };
  const selectedOption = options.find(
    (option: ManagedOptionDataType) => option.isDefault,
  );

  return (
    <WbuContainer id={id} editorType={editorType}>
      <HorizontalTabs
        options={options}
        value={selectedOption ? selectedOption.value : undefined}
        onChange={_onChange}
        type={baseUITypes[tabsStyle]}
      ></HorizontalTabs>
    </WbuContainer>
  );
};

export default WbuHorizontalTabs;
