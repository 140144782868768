
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "WsrTableListItem2558417618",
      {"classes":{"root":"WsrTableListItem2558417618__root","contentContainer":"WsrTableListItem2558417618__contentContainer"},"vars":{"containerOutline":"--containerOutline"},"stVars":{},"keyframes":{}},
      "",
      -1,
      module.id
  );
  