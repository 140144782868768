export type PropValidationRule = {
  type: string;
  tests: Array<{ name: string; params: { [k: string]: any } }>;
  oneOf?: Array<string>;
};
export enum ValidationErrorCode {
  string_length,
  string_min,
  string_max,
  num_min,
  num_max,
  one_of,
}
type TypeOfArray<T> = T extends Array<infer U> ? U : T;
export type ValidationError = {
  code: ValidationErrorCode;
  params: TypeOfArray<PropValidationRule['tests']>['params'];
};
