import {
  defineLeanCompProps,
  InferExtendedPropsSchema,
} from '@wix/lean-components-infra';

import { OnInvalidPayload } from './corvid/invalidPropsSDKFactory';
import { OnKeyPressPayload } from './corvid/keyPressPropsSDKFactory';
import { OnPastePayload } from './corvid/pastePropsSDKFactory';
import { OnChangePayload } from './corvid/changePropsSDKFactory';

export const LABEL_POSITIONS: { [key: string]: string } = {
  top: 'On top',
  left: 'Before',
  right: 'After',
};
const labelPositions = [...([...Object.values(LABEL_POSITIONS)] as const)];

export const PREFIX_AND_SUFFIX_TYPES = {
  none: 'None',
  text: 'Text',
  icon: 'Icon',
};

export const STATUSES: { [key: string]: string } = {
  none: 'Default',
  error: 'Error',
  warning: 'Warning',
  loading: 'Loading',
};

const prefixAndSuffixTypeOptions = [
  ...([...Object.values(PREFIX_AND_SUFFIX_TYPES)] as const),
];
export type PrefixAndSuffixTypeOptions =
  typeof prefixAndSuffixTypeOptions[number];

const statuses = [...([...Object.values(STATUSES)] as const)];

export const ACTIVE_STATES: {
  [key: string]: {
    value: string;
    readOnly: boolean;
    disabled: boolean;
  };
} = {
  ACTIVE: {
    value: 'Active',
    readOnly: false,
    disabled: false,
  },
  READ_ONLY: {
    value: 'Read only',
    readOnly: true,
    disabled: false,
  },
  DISABLED: {
    value: 'Disabled',
    readOnly: false,
    disabled: true,
  },
};

const activeStates = Object.values(ACTIVE_STATES).map(({ value }) => value);

const SIZES: { [key: string]: string } = {
  large: 'Large',
  medium: 'Medium',
  small: 'Small',
};

const sizes = [...([...Object.values(SIZES)] as const)];

export const BORDERS: { [key: string]: string } = {
  standard: 'Standard',
  round: 'Round',
  bottomLine: 'Underline only',
  none: 'None',
};

const borders = [...([...Object.values(BORDERS)] as const)];

const props = defineLeanCompProps(
  ({ schemaBuilder }) => ({
    label: schemaBuilder.shortString().default('Number Input'),
    showTooltip: schemaBuilder.boolean().default(false),
    infoContent: schemaBuilder.longString().default(''),
    placeholderText: schemaBuilder.longString().default(''),
    value: schemaBuilder.integer().default(0),
    prefixType: schemaBuilder
      .stringEnum<PrefixAndSuffixTypeOptions>(prefixAndSuffixTypeOptions)
      .default(PREFIX_AND_SUFFIX_TYPES.none),
    prefixIcon: schemaBuilder.svg(),
    prefixValue: schemaBuilder.shortString().default(''),
    suffixType: schemaBuilder
      .stringEnum<PrefixAndSuffixTypeOptions>(prefixAndSuffixTypeOptions)
      .default(PREFIX_AND_SUFFIX_TYPES.none),
    suffixIcon: schemaBuilder.svg(),
    suffixValue: schemaBuilder.shortString().default(''),
    minAndMaxValues: schemaBuilder.boolean().default(false),
    minimumValue: schemaBuilder.integer().default(0),
    maximumValue: schemaBuilder.integer().default(500),
    invalidMessage: schemaBuilder.longString().default('Invalid value'),
    stepSize: schemaBuilder.integer().default(1),
    status: schemaBuilder.stringEnum(statuses).default(STATUSES.none),
    errorStatusMessage: schemaBuilder.longString().default(''),
    warningStatusMessage: schemaBuilder.longString().default(''),
    loadingStatusMessage: schemaBuilder.longString().default(''),
    activeState: schemaBuilder
      .stringEnum(activeStates)
      .default(ACTIVE_STATES.ACTIVE.value),
    required: schemaBuilder.boolean().default(false),
    size: schemaBuilder.stringEnum(sizes).default(SIZES.medium),
    border: schemaBuilder.stringEnum(borders).default(BORDERS.standard),
    labelPlacement: schemaBuilder
      .stringEnum(labelPositions)
      .default(LABEL_POSITIONS.top),
  }),
  { dmPublish: 'draft' },
).definePanels(({ settingsPanel, layoutPanel }) => {
  layoutPanel?.controls.push('labelPlacement');
  settingsPanel?.controls.omit('labelPlacement');
  settingsPanel?.controls.infoContent.isVisible(
    ({ props: { showTooltip } }) => showTooltip,
  );
  settingsPanel?.controls.minimumValue.isVisible(
    ({ props: { minAndMaxValues } }) => minAndMaxValues,
  );
  settingsPanel?.controls.maximumValue.isVisible(
    ({ props: { minAndMaxValues } }) => minAndMaxValues,
  );
  settingsPanel?.controls.invalidMessage.isVisible(
    ({ props: { minAndMaxValues } }) => minAndMaxValues,
  );
  settingsPanel?.controls.errorStatusMessage.isVisible(
    ({ props: { status } }) => status === 'Error',
  );
  settingsPanel?.controls.warningStatusMessage.isVisible(
    ({ props: { status } }) => status === 'Warning',
  );
  settingsPanel?.controls.loadingStatusMessage.isVisible(
    ({ props: { status } }) => status === 'Loading',
  );
  settingsPanel?.controls.size.modify({
    controlType: 'radioButtons',
  });
  settingsPanel?.controls.activeState.modify({
    controlType: 'radioButtons',
  });
  settingsPanel?.controls.prefixType.modify({
    controlType: 'radioButtons',
  });
  settingsPanel?.controls.suffixType.modify({
    controlType: 'radioButtons',
  });
  layoutPanel?.controls.labelPlacement.modify({
    controlType: 'radioButtons',
  });
  settingsPanel?.controls.prefixIcon.isVisible(
    ({ props: { prefixType } }) => prefixType === 'Icon',
  );
  settingsPanel?.controls.prefixValue.isVisible(
    ({ props: { prefixType } }) => prefixType === 'Text',
  );
  settingsPanel?.controls.suffixIcon.isVisible(
    ({ props: { suffixType } }) => suffixType === 'Icon',
  );
  settingsPanel?.controls.suffixValue.isVisible(
    ({ props: { suffixType } }) => suffixType === 'Text',
  );
  settingsPanel?.controls.suffixIcon.modify({
    preset: 'WSR_ICONS_MEDIUM',
  });
  settingsPanel?.controls.prefixIcon.modify({
    preset: 'WSR_ICONS_MEDIUM',
  });
});

export type NumberInputSDKActions = {
  onInvalid(payload: OnInvalidPayload): void;
  onKeyPress(payload: OnKeyPressPayload): void;
  onPaste(paylod: OnPastePayload): void;
  onChange(paylod: OnChangePayload): void;
};

export type IWsrNumberInputProps = InferExtendedPropsSchema<typeof props> &
  NumberInputSDKActions;

export default props;
