import { Schema } from 'yup';
import { LeanSchema } from './lean-schemas';
import { Codec, LeanTypes } from './types';

const extractNumberTest = (input: Schema<any>, testName: 'min' | 'max') => {
  const { tests } = input.describe();
  const test = tests.find(t => t.name === testName);
  return test ? test.params[testName] : undefined;
};

export const extractMin = (input: Schema<any>) =>
  extractNumberTest(input, 'min');

export const extractMax = (input: Schema<any>) =>
  extractNumberTest(input, 'max');

export const isCodec = (input: unknown): input is Codec<any> => Boolean(input);

export const isYupMetadataLeanSchema = (
  input: unknown,
): input is { leanSchema: LeanSchema.PropertySchema } => {
  try {
    const {
      leanSchema: { type },
    } = input as any;
    return Boolean(type);
  } catch (error) {
    return false;
  }
};

type ArrayInnerType = {
  innerType: {
    meta: {
      leanSchema: {
        type: string;
      };
    };
  };
};

export type MetaWithAdditionalSchemas = {
  meta: {
    additionalSchemas: Record<string, any>;
  };
};

export const isArrayInnerType = (
  input: Record<string, any>,
): input is ArrayInnerType => Boolean(input?.innerType?.meta?.leanSchema?.type);

export const isValidLeanType = (input: string): input is LeanTypes =>
  Object.keys(LeanTypes).includes(input as LeanTypes);

export const hasValidAdditionalSchemas = (
  input: Record<string, any>,
): input is MetaWithAdditionalSchemas =>
  Boolean(input?.meta?.additionalSchemas);
