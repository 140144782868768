import {
  withCompInfo,
  withStateRefs,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

const props = withCompInfo<any, any>()(
  ['compData', 'compStylableClass'],
  ({ compData, compStylableClass }) => {
    return {
      ...{ items: [], activeTabIndex: 0 },
      ...compData,

      className: compStylableClass,
    };
  },
);

export const stateRefs = withStateRefs<any>()([], ({ refApi }) => ({
  editorType: refApi.env.editorType(),
}));

export default createComponentMapperModel({
  props,
  stateRefs,
});
