import { getLeanBuildContextData } from '../utils';

export const getPropTranslationKey = ({
  compProp,
  controlId,
  controlProp,
}: {
  compProp: string;
  controlId: string;
  controlProp: string;
}) => {
  const { compName, namespace } = getLeanBuildContextData();

  return [namespace, compName, compProp, controlId, controlProp]
    .filter(Boolean)
    .join('_');
};
