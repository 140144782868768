
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "WutTabbedContent473718762",
      {"classes":{"root":"WutTabbedContent473718762__root"},"vars":{},"stVars":{},"keyframes":{}},
      "",
      -1,
      module.id
  );
  