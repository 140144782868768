import {
  withCompInfo,
  withStateRefs,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

const props = withCompInfo<any, any>()(['compData'], ({ compData }) => {
  return {
    ...{
      multiline: false,
      iconTooltipText: '',
      linkUrl: '',
      linkText: '',
      skin: 'standard',
      label: '',
    },
    ...compData,
  };
});

export const stateRefs = withStateRefs<any>()([], ({ refApi }) => ({
  editorType: refApi.env.editorType(),
}));

export default createComponentMapperModel({
  props,
  stateRefs,
});
