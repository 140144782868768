import type {
  AnyCompDef,
  PreviewMapperFunc,
  PreviewExtension,
  PreviewWrapperProps,
  IDefaultPreviewWrapperProps,
  CompPreviewInfoDependency,
} from '@wix/editor-elements-types';

type Props = Record<string, any>;

export const withCompPreviewInfo =
  <
    TCompProps extends Props,
    TDefinition extends AnyCompDef,
    TCarmiData = undefined,
  >() =>
  <
    TDependencies extends CompPreviewInfoDependency<TDefinition>,
    TResolverFunc extends PreviewMapperFunc<
      TCompProps,
      TDefinition,
      TDependencies,
      TCarmiData
    >,
  >(
    depsArray: Array<TDependencies>,
    resolver: TResolverFunc,
  ): PreviewExtension<TCompProps, TDefinition, TDependencies, TCarmiData> => {
    const deps = depsArray.reduce(
      (acc, key) => ({ ...acc, [key]: true }),
      {} as Record<TDependencies, true>,
    );

    return {
      deps,
      resolver,
    };
  };

export const withCompPreviewProps = <
  TCompProps extends Props,
  TDefinition extends AnyCompDef,
  TCarmiData = undefined,
  TWrapperProps = IDefaultPreviewWrapperProps,
>() =>
  withCompPreviewInfo<
    PreviewWrapperProps<TCompProps, TWrapperProps>,
    TDefinition,
    TCarmiData
  >();

export function createComponentPreviewMapperModel<TComponentPreviewMapperModel>(
  entry: TComponentPreviewMapperModel,
) {
  return entry;
}
