import * as React from 'react';
import Page from 'wix-style-react/dist/src/Page';
import Button from 'wix-style-react/dist/src/Button';
import { Layout, Cell } from 'wix-style-react/dist/src/Layout';
import PageFooter from 'wix-style-react/dist/src/PageFooter';
import EditableTitle from 'wix-style-react/dist/src/EditableTitle';
import { createSlotsContainer } from '@wix/editor-elements-integrations/dist/thunderbolt/createSlotsContainer';
import type { PageHeaderProps } from 'wix-style-react/dist/src/PageHeader';
import { IWsrPageProps, PAGE_TYPES, FORM_LAYOUTS } from '../WsrPage.props';
import { classes, vars } from './WsrPage.st.css';

const WsrPage: React.FC<IWsrPageProps & { isEditing: boolean }> = props => {
  const {
    id,
    title,
    editableTitle,
    editableTitleValue,
    editableTitleDefaultValue,
    editableTitleMaxLength,
    editableTitleAutoFocus,
    onTitleChange,
    subtitle,
    pageType,
    formLayout,
    showPrimaryButton,
    primaryButtonValue,
    showSecondaryButton,
    secondaryButtonValue,
    mainSlot,
    secondSlot,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
    showBackButton,
    onBackButtonClick,
    updateComponentPropsInViewer,
    pageHeight,
    isEditing,
  } = props;

  const [localValue, setLocalValue] = React.useState(editableTitleValue);

  React.useEffect(() => {
    if (
      editableTitleMaxLength &&
      editableTitleValue.length > editableTitleMaxLength
    ) {
      return;
    }
    setLocalValue(editableTitleValue);
  }, [editableTitleValue, editableTitleMaxLength]);

  const handlePrimaryButtonClick: React.MouseEventHandler<HTMLElement> = () =>
    onPrimaryButtonClick?.({ type: 'primaryButtonClick' });

  const handleSecondaryButtonClick: React.MouseEventHandler<HTMLElement> = () =>
    onSecondaryButtonClick?.({ type: 'secondaryButtonClick' });

  const handleBackButtonClick: PageHeaderProps['onBackClicked'] = () =>
    onBackButtonClick?.({ type: 'backButtonClick' });

  const handleOnTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      editableTitleMaxLength &&
      event.target.value.length > editableTitleMaxLength
    ) {
      return;
    }

    updateComponentPropsInViewer({ editableTitleValue: event.target.value });
    onTitleChange?.({ type: 'titleChange', value: event.target.value });
  };

  const renderActionButtons = (
    <div className={classes.actionButtons}>
      {showSecondaryButton && (
        <Button onClick={handleSecondaryButtonClick} skin="inverted">
          {secondaryButtonValue}
        </Button>
      )}
      {showPrimaryButton && (
        <Button onClick={handlePrimaryButtonClick}>{primaryButtonValue}</Button>
      )}
    </div>
  );

  const renderContainer = ({
    span,
    slot,
  }: {
    span: number;
    slot: JSX.Element;
  }): React.ReactElement => (
    <Cell span={span}>
      <div className={classes.contentContainer}>{slot}</div>
    </Cell>
  );

  const renderContent = (
    <>
      <Layout className={classes.pageLayout}>
        {pageType === PAGE_TYPES.FORM &&
          formLayout === FORM_LAYOUTS.TWO_COLUMNS && (
            <>
              {renderContainer({ span: 8, slot: mainSlot })}
              {renderContainer({ span: 4, slot: secondSlot })}
            </>
          )}

        {((pageType === PAGE_TYPES.FORM &&
          formLayout === FORM_LAYOUTS.SINGLE_COLUMN) ||
          pageType === PAGE_TYPES.DASHBOARD) &&
          renderContainer({ span: 12, slot: mainSlot })}

        {pageType === PAGE_TYPES.FORM && (
          <Cell>
            <PageFooter divider>
              <PageFooter.End>{renderActionButtons}</PageFooter.End>
            </PageFooter>
          </Cell>
        )}
      </Layout>
    </>
  );
  const renderEditableTitle = (
    <EditableTitle
      initialValue={editableTitleValue}
      defaultValue={editableTitleDefaultValue}
      autoFocus={editableTitleAutoFocus}
      maxLength={editableTitleMaxLength}
      onChange={handleOnTitleChange}
      value={localValue}
    />
  );

  return (
    <div
      style={{ [vars.pageHeight]: isEditing ? `${pageHeight}px` : '100vh' }}
      className={classes.root}
      id={id}
    >
      <Page height="100%" className={classes.page}>
        <Page.Header
          title={editableTitle ? renderEditableTitle : title}
          subtitle={subtitle}
          actionsBar={renderActionButtons}
          showBackButton={showBackButton}
          onBackClicked={handleBackButtonClick}
        />
        <Page.Content>{renderContent}</Page.Content>
      </Page>
    </div>
  );
};

export default createSlotsContainer(WsrPage);
