import * as React from 'react';
import ToggleSwitch, {
  ToggleSwitchSize,
  ToggleSwitchSkin,
} from 'wix-style-react/dist/src/ToggleSwitch';
import Tooltip from 'wix-style-react/dist/src/Tooltip';
import FormField, { LabelPlacement } from 'wix-style-react/dist/src/FormField';
import {
  IWsrToggleSwitchProps,
  LABEL_POSITIONS,
  SIZES,
  SKINS,
} from '../WsrToggleSwitch.props';
import { classes } from './WsrToggleSwitch.st.css';

const WsrToggleSwitch: React.FC<IWsrToggleSwitchProps> = props => {
  const {
    id,
    label,
    checked,
    showTooltip,
    tooltip,
    disabled,
    onChange = () => {},
    updateComponentPropsInViewer,
    ...rest
  } = props;

  const handleOnChange = () => {
    updateComponentPropsInViewer({ checked: !checked });
    onChange?.({ type: 'change' } as React.ChangeEvent);
  };

  const size = Object.keys(SIZES).find(
    key => SIZES[key] === rest.size,
  ) as ToggleSwitchSize;

  const labelPlacement = Object.keys(LABEL_POSITIONS).find(
    key => LABEL_POSITIONS[key] === rest.labelPosition,
  ) as LabelPlacement;

  const skin = Object.keys(SKINS).find(
    key => SKINS[key] === rest.skin,
  ) as ToggleSwitchSkin;

  return (
    <div id={id} onClick={!disabled ? handleOnChange : undefined}>
      <FormField label={label} labelPlacement={labelPlacement}>
        <div className={classes.toggleSwitchWrapper}>
          <Tooltip content={tooltip} disabled={!showTooltip}>
            <ToggleSwitch
              checked={checked}
              size={size}
              skin={skin}
              disabled={disabled}
            />
          </Tooltip>
        </div>
      </FormField>
    </div>
  );
};

export default WsrToggleSwitch;
