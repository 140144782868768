import * as React from 'react';

import Card from 'wix-style-react/dist/src/Card';
import { createSlotsContainer } from '@wix/editor-elements-integrations/dist/thunderbolt/createSlotsContainer';
import type { IWsrCardProps } from '../WsrCard.props';
import { st, classes } from './WsrCard.st.css';

const WsrCard: React.FC<IWsrCardProps> = props => {
  const {
    id,
    cardContainer,
    suffixContainer,
    headerTitle,
    headerSubtitle,
    showHeader,
    showSubheader,
    subheaderTitle,
    subheaderSkin,
    showSuffix,
    showShadow,
    removeContentPadding,
  } = props;

  const Content = () => {
    if (removeContentPadding) {
      return (
        <div className={st(classes.contentContainer)}>{cardContainer}</div>
      );
    }
    return (
      <Card.Content>
        <div className={st(classes.contentContainer)}>{cardContainer}</div>
      </Card.Content>
    );
  };

  return (
    <div id={id}>
      <Card className={st(classes.root)} showShadow={showShadow}>
        {showHeader && (
          <Card.Header
            className={classes.header}
            title={headerTitle}
            subtitle={headerSubtitle}
            suffix={
              showSuffix && (
                <div className={st(classes.headerSuffixContainer)}>
                  {suffixContainer}
                </div>
              )
            }
          />
        )}
        {showHeader && !showSubheader && <Card.Divider />}
        {showSubheader && (
          <Card.Subheader title={subheaderTitle} skin={subheaderSkin} />
        )}
        <Content />
      </Card>
    </div>
  );
};

export default createSlotsContainer(WsrCard);
