import * as React from 'react';
import { AccordionSection } from '@wix/wix-base-ui/lib/esm';
import { IWbuAccordionProps } from '../WbuAccordion.props';
import './WbuAccordion.global.scss';
import WbuContainer from '../../WbuContainer/WbuContainer';

const WbuAccordion: React.FC<IWbuAccordionProps> = props => {
  const {
    id,
    label,
    tooltip,
    openByDefault,
    updateComponentPropsInViewer,
    onChange,
    editorType,
  } = props;

  const _onToggle = (isOpen: boolean) => {
    updateComponentPropsInViewer({ openByDefault: isOpen });
    onChange?.({ type: 'change' } as React.ChangeEvent);
  };

  return (
    <WbuContainer id={id} editorType={editorType}>
      <AccordionSection
        label={label || 'Label Example'}
        infoContent={tooltip}
        isOpen={openByDefault}
        onToggle={_onToggle}
        removeBottomDivider={openByDefault}
      />
    </WbuContainer>
  );
};

export default WbuAccordion;
