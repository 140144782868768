import * as React from 'react';

import { RadioButton } from 'wix-ui-tpa/dist/src/components/RadioButton/RadioButton';

import type {
  IWutRadioButtonProps,
  IWutRadioButtonImperativeActions,
} from '../WutRadioButton.types';
import { st, classes } from './style/WutRadioButton.component.st.css';

const WutRadioButton: React.ForwardRefRenderFunction<
  IWutRadioButtonImperativeActions,
  IWutRadioButtonProps
> = (props, ref) => {
  const {
    radioGroupName,
    value,
    checked,
    id,
    className,
    isDisabled,
    onChange,
    onFocus,
    onBlur,
    ariaAttributes,
  } = props;

  const radioButtonRef = React.useRef<RadioButton>(null);

  React.useImperativeHandle(ref, () => ({
    focus: () => {
      radioButtonRef.current?.focus();
    },
    blur: () => {
      radioButtonRef.current?.blur();
    },
  }));

  return (
    <div id={id}>
      <RadioButton
        disabled={isDisabled}
        value={value}
        name={radioGroupName}
        checked={checked}
        className={st(classes.srOnly, className)}
        aria-describedby={ariaAttributes?.describedBy}
        aria-labelledby={ariaAttributes?.labelledBy}
        aria-label={ariaAttributes?.label}
        // @ts-ignore TODO: fix onChange type in wix-ui-core
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={radioButtonRef}
      />
    </div>
  );
};

export default React.forwardRef(WutRadioButton);
