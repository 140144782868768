import type {
  CompInfo,
  AnyCompDef,
  MapperFunc,
  NativeExtension,
  ComponentNativeMappers,
} from '@wix/editor-elements-types';
import type { ValuesToRefs } from '@wix/thunderbolt-refs';

type Props = Record<string, any>;

export const withCompInfo =
  <
    TCompProps extends Props,
    TDefinition extends AnyCompDef,
    TCarmiData = undefined,
  >() =>
  <
    TDependencies extends keyof CompInfo<TDefinition>,
    TResolverFunc extends MapperFunc<
      TCompProps,
      TDefinition,
      TDependencies,
      TCarmiData
    >,
  >(
    depsArray: Array<TDependencies>,
    resolver: TResolverFunc,
  ): NativeExtension<TCompProps, TDefinition, TDependencies, TCarmiData> => {
    const deps = depsArray.reduce(
      (acc, key) => ({ ...acc, [key]: true }),
      {} as Record<TDependencies, true>,
    );

    return {
      deps,
      resolver,
    };
  };

export const withStateRefs =
  <
    StateRefsValues extends Record<string, any>,
    TDefinition extends AnyCompDef = AnyCompDef,
    TCarmiData = undefined,
  >() =>
  <
    TDependencies extends keyof CompInfo<TDefinition>,
    TResolverFunc extends MapperFunc<
      ValuesToRefs<StateRefsValues>,
      TDefinition,
      TDependencies | 'refApi',
      TCarmiData
    >,
  >(
    depsArray: Array<TDependencies>,
    resolver: TResolverFunc,
  ) => {
    const refApiKey: keyof CompInfo<TDefinition> = 'refApi';
    const deps = [...depsArray, refApiKey];
    const withCompInfoFunc = withCompInfo<
      ValuesToRefs<StateRefsValues>,
      TDefinition,
      TCarmiData
    >();
    return withCompInfoFunc(deps, resolver);
  };

export function createComponentMapperModel<
  TComponentMapperModel extends ComponentNativeMappers,
>(mapper: TComponentMapperModel): TComponentMapperModel {
  return mapper;
}
