
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "WutColorPicker3463610379",
      {"classes":{"root":"WutColorPicker3463610379__root"},"vars":{"color-dot-size":"--WutColorPicker3463610379-color-dot-size"},"stVars":{},"keyframes":{}},
      "",
      -1,
      module.id
  );
  