import * as yup from 'yup';
import { DsSchemaObject } from './ds-schemas';
import { LeanSchemaObject, LeanSchema } from './lean-schemas';

export enum LeanTypes {
  big_string = 'big_string',
  text = 'text',
  small_string = 'small_string',
  integer = 'integer',
  double = 'double',
  boolean = 'boolean',
  string_enum = 'string_enum',
  shape = 'shape',
  array = 'array',
  slot = 'slot',
  svg = 'svg',
  color_picker = 'color_picker',
  font_picker = 'font_picker',
}

const leanTypesEnumKeys = (<O extends object, K extends keyof O = keyof O>(
  obj: O,
): K => {
  return Object.keys(obj).filter(k => Number.isNaN(+k))[0] as K;
})(LeanTypes);

type ensureLeanTypesAsKeys<S extends Record<LeanTypes, object>> = {
  [k in LeanTypes]: S[k];
};

type DsSchemas = ensureLeanTypesAsKeys<DsSchemaObject>;

type LeanSchemas = ensureLeanTypesAsKeys<LeanSchemaObject>;

type LeanTypeToYupSchemas<L extends LeanTypes> = ensureLeanTypesAsKeys<{
  big_string: yup.StringSchema;
  text: yup.StringSchema;
  small_string: yup.StringSchema;
  integer: yup.NumberSchema;
  double: yup.NumberSchema;
  shape: yup.ObjectSchema;
  boolean: yup.BooleanSchema;
  array: yup.ArraySchema<any>;
  string_enum: yup.MixedSchema<string>;
  slot: yup.ObjectSchema;
  svg: yup.ObjectSchema;
  color_picker: yup.StringSchema;
  font_picker: yup.StringSchema;
}>[L];

export type Codec<L extends LeanTypes> = {
  extractAdditionalData?(
    yupSchema: LeanTypeToYupSchemas<L>,
  ): Omit<LeanSchemas[L], keyof LeanSchema.LeanSchemaBase<any>>;
  transformToDs(input: LeanSchemas[L]): DsSchemas[L];
};

export type CodecsObject = ensureLeanTypesAsKeys<
  {
    [l in LeanTypes]: Codec<l>;
  }
>;

export type LeanTypeKey = typeof leanTypesEnumKeys;
